import React from "react";
import Header from "../layout/Header";
import HomeBanner from "./HomeBanner";
import OurFeatures from "./OurFeatures";
import InvestorBenefits from "./InvestorBenefits";
import HowItWorks from "./HowItWorks";
import Footer from "../layout/Footer";
import BannerFooter from "../layout/FooterBanner";
import Product from "./Product";

export default function Main() {

    return (
        <>
        
        <div className="color-container">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>
        <div className="c6"></div>
        <div className="c5"></div>
        <div className="c7"></div>
        <Header />
        <HomeBanner />
        <Product />
        <HowItWorks />
        <InvestorBenefits/>
       
            <BannerFooter/>
            <Footer />
        </div>
        
       
      
           
            {/* <OurFeatures /> */}
           
           

           
        </>
    )
}