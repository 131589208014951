import React from "react";

const Check = () => {

    return (
        <>
  
                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={17} viewBox="0 0 17 17" fill="none">
                    <rect x={1} y="1.18262" width="14.6542" height="14.6542" rx="7.32709" stroke="#C9C9C9" strokeWidth="1.04673" />
                    <path d="M4.84677 8.69309L7.41125 11.2576L12.5402 6.12861" stroke="#C9C9C9" strokeWidth="1.04673" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
      
        </>
    );
};
export default Check;
