import { Link, NavLink, useLocation } from "react-router-dom";
import "../style/css/layout/Header.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Container, Form, Button, Offcanvas } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  Web3Modal,
  Web3Button,
  useWeb3Modal,
  Web3ModalProps,
} from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import { purchaseInvestorPlan, findAddressVesting } from "../Web3/Connector";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import config from "../../config/config";
import { CiMenuFries } from "react-icons/ci";
import presentation from '../../assets/style/stake.pdf'
export default function Header() {
  const navigate = useNavigate();

  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const _useDisconnect = useDisconnect();

  const [isProviderLoaded, setisProviderLoaded] = useState(false);

  const _useWeb3ModalProvider = Web3ModalProvider();

  useEffect(() => {
    if (!isProviderLoaded) {
      _useWeb3ModalProvider.then((data) => {
        if (data) {
          setisProviderLoaded(true);
        }
      });
    }
  }, [_useWeb3ModalProvider]);

  const { open } = useWeb3Modal();

  const openConnectiomModel = () => {
    open();
  };
  const handleDownload = () => {
    window.open(`${config.baseUrl}image/stake.pdf`, "_blank",  "noopener,noreferrer");
  };
  const [showDashboard, setShowDashboard] = useState(false);

  useEffect(() => {
    if (isConnected) {
      findVesting();
    }
  }, [isConnected, isProviderLoaded]);

  const findVesting = async () => {
    try {
      console.log("isProviderLoaded :", isProviderLoaded);
      if (isProviderLoaded == true) {
        let result = await findAddressVesting(address, _useWeb3ModalProvider);
        if (result.status) {
          if (result.data > 0) {
            setShowDashboard(true);
          }
        }
      } else {
        console.log("provider not loaded");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const disconnectButton = () => {
    _useDisconnect.disconnect();
    setTimeout(() => {}, 2000);
  };
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <>
      <Navbar expand="lg" className="container">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src="image/stakelogo.svg"  alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll">
            <CiMenuFries style={{ color: "white" }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <div className="nav-mainbar">
              <div className="nav-blank"></div>
              <Nav className="pe-3 middle-nav" style={{ maxHeight: "100px" }}>
              
                
                <Link className="nav-link cool-link" onClick={handleDownload} type="download">
                  Presentation
                </Link>
                <Link
                  to={`${config.baseUrl}whyus`}
                  className={
                    activeLink === `${config.baseUrl}whyus`
                      ? "active nav-link cool-link"
                      : "nav-link cool-link"
                  }
                  onClick={() => handleLinkClick(`${config.baseUrl}whyus`)}
                >
                  Why Sportsmint
                </Link>
                <Link
                  to={`${config.baseUrl}dashboard`}
                  className={
                    activeLink === `${config.baseUrl}dashboard`
                      ? "active nav-link cool-link"
                      : "nav-link cool-link"
                  }
                >
                  {isConnected ? (
                    <div
                      className=""
                      onClick={() =>
                        handleLinkClick(`${config.baseUrl}dashboard`)
                      }
                    >
                      Dashboard
                    </div>
                  ) : (
                    <div className="" onClick={openConnectiomModel}>
                      Dashboard
                    </div>
                  )}
                </Link>
              </Nav>
              <div className="">
                <div className="order-1 order-lg-3 middle-nav">
                  {!isConnected ? (
                    <div className="order-1 order-lg-3 d-flex align-items-center">
                      <div className="text-white why_presale text-center p-0">
                        <button
                          onClick={openConnectiomModel}
                          className="primary-button-investor text-white px-4"
                        >
                          Connect Wallet
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="order-1 order-lg-3 d-flex align-items-center flex-row">
                      <div className="text-white why_presale text-center p-0">
                        {/* Display connected wallet info */}
                        {/* Example, replace with your actual display logic */}
                        {/* <button className="primary-button-investor text-white px-4">
                        {address.toString().substring(0, 6)}...
                        {address.toString().substring(address.length - 6, address.length)}
                      </button> */}
                      </div>
                    </div>
                  )}
                  {isConnected && (
                    <div className="order-1 order-lg-3 d-flex align-items-center">
                      <div className="text-white why_presale text-center p-0">
                        <button
                          onClick={disconnectButton}
                          className="primary-button-investor text-white px-4"
                        >
                          Disconnect Wallet
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
