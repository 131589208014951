import React from "react";
import { useNavigate } from "react-router-dom";

import DataTable, { createTheme } from "react-data-table-component";
import { useParams } from "react-router-dom";
import DashboardHeader from "./header/Header";
import { useEffect, useState } from "react";
import config from "../../config/config";
import { useAccount } from "wagmi";
import "./css/myslot.css";
import "./css/myinvestment.css";
import "../../assets/style/Faqs.css";
import {
  slotDetails,
  claimReleasableAmountMNT,
  upgradeVestingDuration,
} from "../Web3/Connector";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import Cardbg2 from "./Icon/Card_bg2";

import Check from "./Icon/Check";
import Cardbg from "./Icon/Card_bg";
import "../dashboard/css/slotdetail.css";

import toast, { Toaster } from "react-hot-toast";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Modal,
  Nav,
  Tab,
  Table,
} from "react-bootstrap";
import SidebarPro from "./sidebar/Sidebar";

export default function Slotdetail(props) {
  const { vestingId } = useParams();

  const [activeCard, setActiveCard] = useState("");
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  if (!isConnected) {
    navigate(`${config.baseUrl}`);
  }
  if(isDisconnected){
    navigate(`${config.baseUrl}`);

  }
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShow1(false);
  };

  const [ClubData, setClubData] = useState([]);


  const { collapsed, setCollapsed } = props;

  // match height code start

  const HeightMatch = () => {
    const hMtch = document.getElementsByClassName("h_match_myclub");
    const ary = [];
    for (let i = 0; i < hMtch.length; i++) {
      ary.push(hMtch[i].offsetHeight);
    }
    let max = Math.max(...ary);

    for (let i = 0; i < hMtch.length; i++) {
      hMtch[i].style.height = max + "px";
    }
  };

  useEffect(() => {
    return HeightMatch();
  }, [ClubData.length ? true : false]);

  const _useWeb3ModalProvider = Web3ModalProvider();

  const [vestingSchedulesId, setVestingScheduleId] = useState("");
  const [openNote, setOpenNote] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loaderButton, setLoaderButton] = useState(false);

  const [consten, setContent] = useState([
    //"Investors allotted MNT tokens equal to investment at current rate.",
    "Allocated non transferrable MNT tokens equivelent to investment price.",
    "24-month lock-in period starts when platform goes live.",
    "5% of tokens released from 25th month, must be claimed.",
    //"10% revenue shared with each group from the launch month.",
    "Revenue sharing MNT 10% for each group monthly.",
    "Option to renew investment in 23rd month for continued revenue sharing.",
    "If renewed, 5% tokens will be released after renewed term.",
    //"Revenue share credited monthly in MNT tokens.",
    //"Allotted tokens non-transferable.",
  ]);

  // useEffect(()=>{
  // getSlotdetails()
  // },[vestingId])

  const [cardDetails, setCardDetails] = useState([]);
 const [tableData,setTableData]=useState({releasedTT:0,claimAvailable:false,releasableAmount:0,upgradeAvailable:false})
  const getSlotdetails = async () => {
    try {
      if (isConnected) {
        setLoader(true);
        let result = await slotDetails(vestingId, _useWeb3ModalProvider);
        if (result.status) {
          setCardDetails(result.data);
          let releasedTT=parseFloat(result.data[0].releasedTT);
          let claimAvailable=result.data[0].claimAvailable;
          let upgradeAvailable=result.data[0].upgradeAvailable;
          let releasableAmount=result.data[0].releasableAmount;
          setTableData(releasedTT,claimAvailable,releasableAmount,upgradeAvailable)
        }
        setLoader(false);
        setOpenNote(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: 'Released ',
      selector: row => row.releasedTT,
    },
    {
      name: 'Withdrawable',
      selector: row => row.releasableAmount,
    },
    {
      name: 'Action',
      cell: row => <span><Button className="btn_uni btn-sm me-3">Claim</Button><Button className="btn_uni2 btn-sm me-3">Upgrade</Button></span>,
      allowOverflow: true,

  
    }
  ];

  const data = [
    {
      releasedTT: '254',
      releasableAmount: '240',
    },

  ]
  const tableCustomStyles = {
    headRow: {
      style: {
        color: "rgb(219, 219, 219)",
        background:
          "linear-gradient(90deg, rgba(5, 214, 217, 0.20) 0.3%, rgba(249, 7, 252, 0.20) 99.65%)",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "rgb(219, 219, 219)",
        backgroundColor: "rgba(23, 25, 28, 1)",
        fontSize: "16px",
      },
    },
    pagination: {
      color: "rgb(219, 219, 219)",
      background: "white",
    },
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#268bd2",
        secondary: "rgba(215, 215, 215, 0.32)",
      },
      background: {
        default: "#000",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#6b6a6b",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );



  const claimModal = () => {
    setShow(true);
  };

  const upgradeModal = () => {
    console.log("jj");
    setShow1(true);
  };

  const claimReleasableAmount = async () => {
    try {
      setLoaderButton(true);
      let result = await claimReleasableAmountMNT(
        address,
        _useWeb3ModalProvider,
        vestingId
      );

      if (result.status) {
        toast.success(result.message);
        setLoaderButton(false);
        setShow(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(result.message);
        setLoaderButton(false);
        setShow(false);
      }
    } catch (error) { }
  };

  const upgradeVesting = async () => {
    try {
      setLoaderButton(true);
      let result = await upgradeVestingDuration(
        address,
        _useWeb3ModalProvider,
        vestingId
      );
      console.log(result);
      if (result.status) {
        toast.success(result.message);
        setLoaderButton(false);
        setShow1(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(result.message);
        setLoaderButton(false);
        setShow1(false);
      }
    } catch (error) { }
  };
  return (
    <>
      <div className="dashboard">
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />

          {openNote && (
            <div className="dashboard_home mt-5">
              <section className="mb-3">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8} className="mt-3 text-center">
                      <Card className="p-4 border-dark bg-transparent rounded-4">
                        <h5 className="text-uppercase mb-3">Note</h5>
                        <p className="mb-3">
                          This information is important. Please click here to
                          open it{" "}
                        </p>
                        <Row className="justify-content-center">
                          <Col lg={4}>
                            <Button
                              className="btn_uni w-auto"
                              onClick={() => {
                                if (!loader) {
                                  getSlotdetails();
                                }
                              }}
                            >
                              {loader ? "Please wait ..." : "Click here"}
                            </Button>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          )}

          {!openNote && !loader && (
            <div className="dashboard_home mt-5">
              <section className="mb-3 slotdetail">
                <Container>
                  <Col lg={12} className="mb-4">
                    <h4>Investment Detail</h4>
                  </Col>
                  <Row>
                    <Col xl={5} lg={6} className="mb-5">
                      {cardDetails.length > 0 && (
                        <div
                          className={`box h-auto d-block p-4 text-start ${cardDetails[0].color} overflow-hidden goldCard`}
                        >
                          <img src="card/circle.png" className="bg-img" />
                          <div className="position-relative">
                            {/* <div className="d-flex cardline justify-content-between"> */}
                            <div className="mb-4">
                              <div className="d-flex align-items-center justify-content-between">
                                <h4 className="text-gradient">
                                  {cardDetails[0].name}
                                </h4>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <h1 className="text-gradient">
                                  ${cardDetails[0].amount}
                                </h1>
                                <h5>Invested</h5>
                              </div>
                            </div>

                            {/* </div> */}
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-5 position-relative">
                            <div>
                              <h1 className="d-flex align-items-center">
                                <img
                                  src="card/coin/mntcoin.png"
                                  width={`40px`}
                                  height={`40px`}
                                  className="object-fit-contain"
                                />
                                &nbsp;
                                {parseFloat(cardDetails[0].totalMNT).toFixed(2)}
                              </h1>
                            </div>
                            <div className="v-line"></div>
                            <div>
                              <h5>MNT Allocated</h5>
                            </div>
                          </div>


                        </div>
                      )}
                      <div className="d-flex justify-content-between bg-white px-4 py-1 DateBox">
                        <div><h6 className="mb-0 small">Invest Date :</h6>	<h6 className="fw-light  mb-0">May 24, 2024, 15:41:10</h6></div>
                        <div>	<h6 className="mb-0 small">Lock in :</h6> <h6 className="fw-light mb-0 ">May 14, 2026, 15:41:10</h6></div>
                      </div>
                    </Col>
                    <Col xl={7} lg={6} className="mb-5">
                      <h5 className="mb-2">Includes:</h5>
                      <div className=" border-0 p-0">
                        <ul className="mb-3 list-group bg-transparent">
                          {consten.map((item) => (
                            <li className="list-group-item border-0 bg-transparent">
                              <Check />
                              <span className="ms-3">{item}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  { cardDetails.length>0 && cardDetails[0].claimAvailable && 
                  <>
                  <Row className="justify-content-center">
                 <div>
                        <h5 className="mb-4">Vesting Action</h5>
                    
                      </div>
                      <Col lg={12} className="mb-5">
                
                  
                     
                   <DataTable
                        columns={columns}
                        data={data}
                        customStyles={tableCustomStyles}
                        theme="solarized"
                      />
                      
                     
                    </Col>
                   
                  </Row>
                   </> }
                  <Row>
                    <Col lg={12}>
                      <div>
                        <h5 className=" mb-4">Transaction History</h5>
                    
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="Released"
                      >
                        <Row>
                          <Col sm={12} className="mb-4">
                            <Nav
                              variant="pills"
                              className="border-bottom border-dark"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="Released">
                                  Released
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="Revenue">Revenue</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="Released">
                               
                                {cardDetails.length > 0 &&
                                  cardDetails[0].releasedHistory.length > 0 && (
                                    <div className="niceTable">
                                      <Table responsive hover>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Claimed Date</th>
                                            <th>MNT</th>
                                            <th>Transaction Hash</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cardDetails[0].releasedHistory.map(
                                            (item, index) => (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.date}</td>
                                                <td>
                                                  {item.releasedAmount} MNT
                                                </td>
                                                <td>
                                                  <a
                                                    href={`${config.blockchainUrl}${item.transactionHash}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.transactionHash
                                                      .toString()
                                                      .substring(0, 10)}
                                                    ...
                                                    {item.transactionHash
                                                      .toString()
                                                      .substring(
                                                        item.transactionHash
                                                          .length - 10,
                                                        item.transactionHash
                                                          .length
                                                      )}
                                                  </a>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                              </Tab.Pane>
                              <Tab.Pane eventKey="Revenue">
                                {cardDetails.length > 0 &&
                                  cardDetails[0].earnedHistory.length > 0 && (
                                    <div className="niceTable">
                                      <Table responsive hover>
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Revenue Distribution Date</th>
                                            <th>MNT</th>
                                            <th>Transaction Hash</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cardDetails[0].earnedHistory.map(
                                            (item, index) => (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.date}</td>
                                                <td>
                                                  {parseFloat(
                                                    item.earnedAmount
                                                  ).toFixed(2)}{" "}
                                                  MNT
                                                </td>
                                                <td>
                                                  <a
                                                    href={`${config.blockchainUrl}${item.transactionHash}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {item.transactionHash
                                                      .toString()
                                                      .substring(0, 10)}
                                                    ...
                                                    {item.transactionHash
                                                      .toString()
                                                      .substring(
                                                        item.transactionHash
                                                          .length - 10,
                                                        item.transactionHash
                                                          .length
                                                      )}
                                                  </a>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          )}
        </div>
      </div>
      <Modal show={show} className="niceModal">
        <Modal.Header className="border-bottom p-3 px-3">
          <Modal.Title className="text-center">Confirm Claim </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pb-4 px-3">
          <div className="text-center">
            <p className="mb-4">
              Are you sure you want to claim reward. Once you start claiming
              reward ,you were not able to upgrade.{" "}
            </p>
            {loaderButton ? (
              <div>
                <Button variant="secondary">Please wait...</Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    claimReleasableAmount();
                  }}
                  className="me-2"
                >
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show1} className="niceModal">
        <Modal.Header className="border-bottom p-3 px-3">
          <Modal.Title className="text-center">Confirm Upgrade </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pb-4 px-3">
          <div className="text-center">
            <p className="mb-4">
              Are you sure you want to upgrade locking duration. If you upgrade
              your locking duration you will be not able to claim reward till
              end of new locking period{" "}
            </p>
            {loaderButton ? (
              <div>
                <Button variant="secondary">Please wait...</Button>
              </div>
            ) : (
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    upgradeVesting();
                  }}
                  className="me-2"
                >
                  Yes
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  No
                </Button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
