import { Route, Routes,useLocation } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../src/assets/style/ProfileUpdation.css';
import Main from './components/Home';
import HomeBanner from './components/Home/HomeBanner';
import HowItWorks from './components/Home/HowItWorks';
import InvestorBenefits from './components/Home/InvestorBenefits';
import OurFeatures from './components/Home/OurFeatures';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import Dashboard2 from './components/dashboard/Dashboard2.jsx';
import MyInvestmentTwo from './components/dashboard/MyInvestmentTwo.jsx';
import Slotdetail from './components/dashboard/Slotdetail.jsx';
import Investmentdetail from './components/dashboard/Investmentdetail.jsx';


import config from './config/config';
import { useEffect, useMemo, useState } from 'react';
import WhyUs from './components/Home/WhyUs.jsx'
function App() {

  
  const [collapsed, setCollapsed] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (width < 600) {
      setCollapsed(true)
    } else {
      setCollapsed(false)
    }
  }, [window.innerWidth])

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return (
    <>
      <Routes>
        <Route path={`${config.baseUrl}`} element={<Main />} />
        <Route path={`${config.baseUrl}whyus`} element={<WhyUs />} />
        <Route path={`${config.baseUrl}dashboard`} element={<Dashboard2 collapsed={collapsed} setCollapsed={setCollapsed}/>} />
        <Route path={`${config.baseUrl}myInvestment`} element={<MyInvestmentTwo collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path={`${config.baseUrl}investment-detail/:vestingId`} element={<Investmentdetail collapsed={collapsed} setCollapsed={setCollapsed} />} />
        <Route path={`${config.baseUrl}slotdetail/:vestingId`} element={<Slotdetail collapsed={collapsed} setCollapsed={setCollapsed} />} />

      </Routes>
    </>
  );
}

export default App;
