import React from "react";
import { Sidebar, Menu, MenuItem  } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { TbPackages } from "react-icons/tb";
import config from "../../../config/config";
import {  useAccount } from "wagmi";
import '../../../assets/style/Sidebar.css'

import toast, { Toaster } from "react-hot-toast";

export default function SidebarPro(props) {
  const { collapsed, setCollapsed } = props;

  const { address, isConnecting, isDisconnected, isConnected } = useAccount();


  const connectionError = () => {
    toast.error("Please connect wallet");
  };
  return (
    <>
      <Toaster />
      <Sidebar className="sidebar" collapsed={collapsed}>
        <div className={collapsed ? "sidebar_header2 mb-3 d-flex justify-content-center align-items-center" : "sidebar_header"}  >
         
          <Link to={`${config.baseUrl}`}>
            <img
           
             src={
              collapsed
                ? "image/listicon.svg"
                : "image/stakelogo.svg"
            }
         
              alt=""
              className="logo_img "
            />
          </Link>
        </div>

        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: disabled ? "#f5d9ff" : "#d359ff",
                  backgroundColor: active ? "#eecef9" : undefined,
                };
            },
          }}
        >
          {/* <MenuItem
            active={window.location.pathname === `${config.baseUrl}`}
            component={<Link to={`${config.baseUrl}`} />}
            icon={<MdDashboard />}
          >
           Investment Pool
          </MenuItem> */}
          <MenuItem
              active={window.location.pathname === `${config.baseUrl}dashboard`}
              icon={<MdDashboard />}
              component={<Link to={`${config.baseUrl}dashboard`} />}
             
            >
              Dashboard
            </MenuItem>
          {/* {isConnected ? (
            <MenuItem
              active={window.location.pathname === `${config.baseUrl}myInvestment`}
              icon={<TbPackages />}
              component={<Link to={`${config.baseUrl}myInvestment`} />}
            >
              My Investment
            </MenuItem>
          ) : (
            <MenuItem
              icon={<TbPackages />}
              onClick={() => {
                connectionError();
              }}
            >
              My Investment
            </MenuItem>
          )} */}

{isConnected ? (
            <MenuItem
              active={window.location.pathname === `${config.baseUrl}myInvestment`}
              icon={<TbPackages />}
               
              component={<Link to={`${config.baseUrl}myInvestment`} />}
            >
              My Investment 
            </MenuItem>
          ) : (
            <MenuItem
              icon={<TbPackages />}
              onClick={() => {
                connectionError();
              }}
            >
              My Investment 
            </MenuItem>
          )}
        </Menu>

       
      </Sidebar>
    </>
  );
}
