const { useAccount } = require("wagmi");

const Web3ModalProvider = async () => {
try{
  const _useAccount = useAccount();
  const data = await _useAccount.connector?.getProvider();

  return data;
}catch(error){
  console.log(error)
}

};
export default Web3ModalProvider;
