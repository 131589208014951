import React from 'react'
import Header from '../layout/Header'
import { Col, Row } from 'react-bootstrap'
import '../../assets//style/WhyUs.css'
import BannerFooter from '../layout/FooterBanner'
import Footer from '../layout/Footer'
import config from "../../config/config";
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

const WhyUs = () => {

  return (
   <>
    <div className="color-container px-3 ">
        <div className="c1"></div>
        <div className="c2"></div>
        <div className="c3"></div>
        <div className="c4"></div>
        <div className="c6"></div>
        <div className="c5"></div>
        <div className="c7"></div>
        <Header />
        <section className='container whyus-header my-5'>
          <div className="whyus-s1 h-100 d-flex align-items-center">
          <Row className='gx-5 gy-4'>
            <Col lg={6}>
<img src="image/whyusbanner.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
            <Col lg={6} className='d-flex justify-content-center flex-column'>
              <h2 className='whyus-heading'>
              Why Choose <br /><span>Sportsmint?</span>
              </h2>
              <h4 className='whyus-page-heading'>
              Unmatched Expertise
              </h4>
              <p>
              Choose SportsMint for our commitment to innovation, security, and decentralized investment opportunities. With us, you're not just investing but becoming part of a movement to redefine sports fantasy.
              </p>
              {/* <button className='px-4 py-2 rounded bg-transparent text-light shadow-none whyus-button w-50'>
              <a >
              Start Investing
              </a>
               
              </button> */}
            </Col>
          </Row>
        </div>
        </section>

        <section className='container '>
          <h4 className='whyus-page-heading mb-2'>
          Innovative Features
          </h4>
          <p>
          Sportsmint is at the forefront of innovation in the fantasy sports industry. We constantly update our platform with new features, ensuring that our users have access to the latest tools and functionalities. From advanced player statistics to real-time game updates, we've got you covered.
          </p>
          <Row className='gy-3 mt-3'>
            <Col lg={4}>
              <div className="whyus-card-border">
              <div className="whyus-card1 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon1.svg" alt="" />
                <h5>Players <br /> NFT 
                Marketplace</h5>
              </div>
              </div>
            </Col>
            <Col lg={4}>
           <div className="whyus-card-border">
<div className="whyus-card2 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon2.svg" alt="" />
                <h5>Fantasy <br />Gaming 
                Contest</h5>
              </div>

           </div>
                        </Col>
            <Col lg={4}>
            <div className="whyus-card-border">
               <div className="whyus-card1 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon3.svg" alt="" />
                <h5>Decentralized <br />
                Reward Distribution</h5>
              </div>
            </div>
           
            </Col>
            <Col lg={4}>
            <div className="whyus-card-border">
               <div className="whyus-card2 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon4.svg" alt="" />
                <h5>Challenger<br /> Ground</h5>
              </div>
            </div>
             
            </Col>
            <Col lg={4}>
           <div className="whyus-card-border">
<div className="whyus-card1 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon5.svg" alt="" />
                <h5>AI<br />
                implementation</h5>
              </div>

           </div>
                        </Col>
            <Col lg={4}>
            <div className="whyus-card-border">
                <div className="whyus-card2 d-flex align-items-center flex-column justify-content-center gap-3">
                <img src="image/whyusicon6.svg" alt="" />
                <h5>Global<br /> Currency 
                Acceptance </h5>
              </div>
            </div>
          
            </Col>
          </Row>
        </section>
        

        <section className='container whyus-header my-5'>
          <div className="whyus-s1 h-100 d-flex align-items-center flex-column gap-5">
          <Row className='gx-5 gy-4 '>
          <Col lg={6} className='d-flex justify-content-center flex-column gap-3 whyus-points'>
              

              <h4 className='whyus-page-heading'>
              NFT Marketplace

              </h4>
              <p>
              Our NFT Marketplace empowers users to buy, sell, and trade player NFTs seamlessly. Users can create their fantasy team and participate in various contests. As players excel in real matches, your NFTs mature, increasing their value and allowing you to sell them at a desirable price.

              </p>
              
            </Col>
            
            <Col>
<img src="image/whyusbanner2.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
           
          </Row>

          <Row className='gx-5 gy-4 d-flex flex-row-reverse'>
         
            
           
            <Col lg={6} className='d-flex justify-content-center flex-column gap-3'>
              

              <h4 className='whyus-page-heading'>
              Clash Grounds

              </h4>
              <p>
              At SportsMint, we enhance sports fantasy's thrill with public and private contests. Join public contests by paying a nominal fee and tracking your points in real-time during live matches. Create private contests to compete exclusively with friends, adding a personalized touch to your sports fantasy experience.

              </p>
              {/* <button className='px-4 py-2 rounded bg-transparent text-light shadow-none whyus-button w-50'>
                Start Investing
              </button> */}
            </Col>
            <Col>
<img src="image/whyusbanner7.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
          </Row>
          

          <Row className='gx-5 gy-4 '>
         
            
          
            <Col lg={6} className='d-flex justify-content-center flex-column gap-3'>
              
            <h4 className='whyus-page-heading'>
            Challenger Ground

              </h4>
              <p>
              Experience the ultimate competitive thrill with our Challenger Ground. Engage in 1-on-1 climactic battles or group vs group showdowns. Use player performance data and pitch conditions to build a strong team and employ winning strategies. Challenge others and prove your team's supremacy.

              </p>
              {/* <button className='px-4 py-2 rounded bg-transparent text-light shadow-none whyus-button w-50'>
               Join Community
              </button> */}
             
            </Col>
            <Col>
<img src="image/whyusbanner4.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
          </Row>
          <Row className='gx-5 gy-4 d-flex flex-row-reverse'>
          <Col lg={6} className='d-flex justify-content-center flex-column gap-3'>
              
          <h4 className='whyus-page-heading'>
          AI-Generated Teams

              </h4>
              <p>
              Struggling to build a strong team without current stats? Let our AI-driven system assist you. Our advanced algorithms analyze past player performances to help you create standout teams. With AI-generated insights, building a top-performing team has never been easier.

              </p>
              
              
            </Col>
            
            <Col>
<img src="image/whyusbanner5.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
           
          </Row>

          <Row className='gx-5 gy-4 '>
        
            <Col lg={6} className='d-flex justify-content-center flex-column gap-3'>
              

              <h4 className='whyus-page-heading'>
              Live Match Tracking

              </h4>
              <p>
              Experience the excitement of live sports with SportsMint's real-time match-tracking feature. This feature allows you to keep track of player performances, match statistics, and team scores as they happen. It also allows you to make informed decisions during contests and adjust your strategies on the fly, ensuring you stay ahead of the competition.

              </p>
             
            </Col>

            <Col>
<img src="image/whyusbanner6.png" style={{width:"100%", height:"100%"}} alt="" />
            </Col>
          </Row>
        </div>
        </section>
        <BannerFooter />
        <Footer />
        </div>
   </>
  )
}

export default WhyUs