import Navbar from 'react-bootstrap/Navbar';

import { useState, useEffect } from 'react';
import { useDisconnect, useAccount } from "wagmi";

import { useNavigate } from "react-router-dom";

import { useWeb3Modal } from "@web3modal/wagmi/react";

import { Button, Modal } from 'react-bootstrap';
import '../../../assets/style/Header.css'
import config from '../../../config/config';

export default function DashboardHeader(props) {
  const navigate = useNavigate();

  const { collapsed, setCollapsed } = props
  const [OpenWalletModel, setOpenWalletModel] = useState(false);

  const [show, setShow] = useState(false);
  const [isMobile, setisMobile] = useState(false);


  const { address, isConnected,isDisconnected } = useAccount()
  const _useWeb3Modal = useWeb3Modal();

  useEffect(() => {
    if(isDisconnected){
        navigate(`${config.baseUrl}`);
    }
},[isDisconnected])
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (!_useWeb3Modal.isOpen) {
      handleClose();
    }
  }, [_useWeb3Modal]);

  useEffect(() => {
    if (props.connectWallet) {
      if (isMobile) {
        setOpenWalletModel(true);
      } else {
        setShow(true);
      }
    }
  }, [props.connectWallet])
  useEffect(() => {
    if (show) {

      _useWeb3Modal.open();
    }
  }, [show]);

  useEffect(() => {
    setisMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    if (window.ethereum) {
      setisMobile(false)
    }
    setTimeout(() => {
      if (window.ethereum) {
        setisMobile(false)
      }
    }, 500);
  }, [])




  return (
    <>
      <section className='header_section'>
        <div className="container">
          <div className="header">
            <Navbar expand="lg" id='navbar' className=" ps-0 justify-content-between"  data-bs-theme="dark">

              {
                collapsed
                  ?
                  <div></div>
                  :
                  <div></div>
              }

              <div style={{ cursor: "pointer" }} className='d-flex align-items-center gap-4 py-2' >
       <div>
       {isConnected ? `${address.toString().substring(0, 7)}....${address.toString().substring(address.length - 7, address.length)}` : 'Connect Wallet'}
       </div>
                {isMobile ?
                  <Button className="btn_uni me-3 primary-button-investor"
                    onClick={() => setOpenWalletModel(true)}
                  >
                    {isConnected ? "Disconnect Wallet" : 'Connect Wallet'}
                  </Button>
                  :
                  <Button className="btn_uni me-3 primary-button-investor" onClick={() => setShow(true)}>
                    {isConnected ? "Disconnect Wallet" : 'Connect Wallet'}
                  </Button>
                }
              </div>


            </Navbar>
          </div>
        </div>
      </section>


      <Modal
        show={OpenWalletModel}
        onHide={() => setOpenWalletModel(false)}
        // backdrop="static"
        className="modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <button
          className="btn_uni order-1 order-lg-3 mt-3"
          variant="primary"
          onClick={() => {
            setOpenWalletModel(false)
            setShow(true)
          }}
        >
          <img
            src="Icons/wallet.png"
            alt=""
            style={{ height: '25px' }}
            className="img-fluid"
          />
          <span> Wallet Connect</span>
        </button>
        <button
          className="btn_uni order-1 order-lg-3 mt-3"
          variant="primary"
          onClick={() => window.location.href = `https://metamask.app.link/dapp/${window.location.href}`}
        >
          <img
            src="Icons/metamask.png"
            alt=""
            style={{ height: '30px' }}
            className="img-fluid"
          />
          <span> Metamask </span>
        </button>
      </Modal>


    </>
  )
}