import React from "react";
import { useNavigate } from "react-router-dom";

import DataTable, { createTheme } from "react-data-table-component";

import DashboardHeader from "./header/Header";
import { useEffect, useState } from "react";
import config from "../../config/config";
import { useAccount } from "wagmi";
import "./css/myslot.css";
import "./css/myinvestment.css";
import "../../assets/style/Faqs.css";
import './css/Dashboard2.css';
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import Cardbg2 from "./Icon/Card_bg2";

import {
  getParams2,
  claimReleasableAmountMNT,
  upgradeVestingDuration,
} from "../Web3/Connector";
import toast, { Toaster } from "react-hot-toast";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Modal,
} from "react-bootstrap";
import SidebarPro from "./sidebar/Sidebar";


export default function Dashboard2(props) {
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  if (!isConnected) {
    navigate(`${config.baseUrl}`);
  }
  if (isDisconnected) {
    navigate(`${config.baseUrl}`);

  }

  const [ClubData, setClubData] = useState([]);


  createTheme(
    "solarized",
    {
      text: {
        primary: "#268bd2",
        secondary: "rgba(215, 215, 215, 0.32)",
      },
      background: {
        default: "#000",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#6b6a6b",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const { collapsed, setCollapsed } = props;

  // match height code start

  const HeightMatch = () => {
    const hMtch = document.getElementsByClassName("h_match_myclub");
    const ary = [];
    for (let i = 0; i < hMtch.length; i++) {
      ary.push(hMtch[i].offsetHeight);
    }
    let max = Math.max(...ary);

    for (let i = 0; i < hMtch.length; i++) {
      hMtch[i].style.height = max + "px";
    }
  };

  useEffect(() => {
    return HeightMatch();
  }, [ClubData.length ? true : false]);


  const _useWeb3ModalProvider = Web3ModalProvider();

  const [isProviderLoaded, setisProviderLoaded] = useState(false);
  const [params, setParams] = useState([]);
  const [openNote, setOpenNote] = useState(true)
  const [loader, setLoader] = useState(true)
  const [loaderButton, setLoaderButton] = useState(false)


  useEffect(() => {
    if (!isProviderLoaded) {
      _useWeb3ModalProvider.then(data => {
        if (data) {
          setisProviderLoaded(true);
          getParamsMethod();
        }
      })
    }
  }, [_useWeb3ModalProvider]);
  const getParamsMethod = async () => {
    if (isConnected) {

      setLoader(true)
      let getParamsResult = await getParams2(address, _useWeb3ModalProvider);
      if (getParamsResult.status) {
        setParams(getParamsResult.data);

      }

      setLoader(false)
      setOpenNote(false)
    }
  };
  const columns2 = [
    {
      name: "Amount",

      cell: (row) => (
        <div className="staking-table-button " style={{ width: "150px" }}>
          <p className="mb-0"> <img src="image/mrmintcoin.png" width={20} height={20} style={{ marginRight: 10 }} alt="" />${row.amount} MNT</p>
        </div>
      ),
      width: "25%"
    },
    {
      name: "Time",
      selector: (row) => row.time,

    },
    {
      name: "Status",

      cell: (row) => (
        <div
          className="staking-table-button py-2"
          style={{
            width: "150px",
            borderRadius: "5px",
            backgroundColor: row.Status === "Completed" ? "rgba(115, 197, 143, 0.20)" : "rgba(243, 78, 78, 0.20)"
          }}
        >
          <p className="mb-0">{row.Status}</p>
        </div>


      ),

    },


    {
      name: "Tx Hash",
      selector: (row) => row.hash,

    },


  ];

  const data2 = [
    {
      id: 1,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 2,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 3,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 4,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Failed",
    },
    {
      id: 5,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
  ];
  const customStyles2 = {
    rows: {
      style: {
        minHeight: "62px", // override the row height
        backgroundColor: "transparent",
        color: "white",
        paddingLeft: "10px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "rgba(102, 104, 206, 0.303)",
        borderRadius: "16px 16px 0px 0px",
        color: "white",

        paddingLeft: "10px",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",

        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
      },
    },
    table: {
      style: {
        color: "white",
        backgroundColor: "transparent",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        backgroundColor: "transparent",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        borderBottom: "1px solid #A5A5A523",
      },
    },
    headeRow: {
      style: {
        borderBottom: "1px solid #A5A5A5",
        backgroundColor: "red",
      },
    },
    pagination: {
      style: {
        color: "white",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "px",
        cursor: "pointer",
        transition: "0.4s",
        color: "white",
        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "white",
          fill: "white",
        },
      },
    },
  };

  return (
    <>
      <div className="dashboard color-container">
        <div className="c1"></div>
        <div className="investor-c2"></div>
        <div className="investor-c3"></div>
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}


          />

          <div className="dashboardNew">
            <div className="dashboard_home mt-5 mx-3">
              <section className="mb-5 px-1 px-sm-5 ">


                <div className="newdashboard">
                  <div className="section01">
                    <h2 className=" text-start hd mb-4">Dashboard</h2>
                    <Row className="gy-3">
                      <Col lg={3} md={6} sm={12}>
                        <div className="dashcard">
                          <img src="image/usdt.svg" style={{ width: 100, height: "100px", objectFit: "contain" }} alt="" />
                          <div>
                            <h2 className="dashboard-h2">${parseFloat(params[0]?.totalUSDT || 0).toFixed(2)}</h2>
                            <p className="dashboard-p">Total USDT <span>Invested</span></p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <div className="dashcard">
                          <img src="image/mrmintcoin.png" style={{ width: 100, height: "100px", objectFit: "contain" }} alt="" />
                          <div>
                            <h2 className="dashboard-h2">{parseFloat(params[0]?.totalMNT || 0).toFixed(2)} <span>MNT</span></h2>
                            <p className="dashboard-p">Total MNT <span>Allocated</span></p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <div className="dashcard">
                          <img src="image/mrmintcoin.png" style={{ width: 100, height: "100px", objectFit: "contain" }} alt="" />
                          <div>
                            <h2 className="dashboard-h2">{parseFloat(params[0]?.released || 0).toFixed(2)} <span>MNT</span></h2>
                            <p className="dashboard-p">Total MNT <span>Released</span></p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={3} md={6} sm={12}>
                        <div className="dashcard">
                          <img src="image/mrmintcoin.png" style={{ width: 100, height: "100px", objectFit: "contain" }} alt="" />
                          <div>
                            <h2 className="dashboard-h2">{parseFloat(params[0]?.earnedAmount || 0).toFixed(2)} <span>MNT</span></h2>
                            <p className="dashboard-p">Total Revenue <span>Released</span></p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <br />
                  <div className="section02" style={{display:'none'}}>
                    <h2 className=" text-start hd">My Transactions</h2>
                    <div className="text-end d-flex flex-row gap-5 justify-content-sm-end justify-content-start">
                      <a href="" className="dashboard-option cool-link">RELEASED</a>
                      <a href="" className="dashboard-option cool-link">REVENUE</a>
                    </div>
                    <br />
                    <DataTable
                      columns={columns2}
                      data={data2}
                      customStyles={customStyles2}
                      pagination
                    />
                  </div>
                </div>
              </section>

            </div>
          </div>


        </div>
      </div>

    </>
  );
}
