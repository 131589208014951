import React,{useEffect,useState} from "react";
import "../../components/style/css/pages/Home/Products.css";
import { Button, Col, Row } from "react-bootstrap";
import { IoMdArrowDropup } from "react-icons/io";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
import { BsTelegram } from "react-icons/bs";
import { MdCatchingPokemon } from "react-icons/md";
const Product = () => {

  const [price,setPrice] = useState('')

  useEffect(()=>{
    getPrice()
     // Set an interval to call getPrice every 2 minutes (120000 ms)
     const intervalId = setInterval(() => {
      getPrice();
    }, 120000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
   },[])

   const getPrice = async () => {
    try {
      const response = await fetch('https://api.coinstore.com/api/v1/market/trade/MNTUSDT?size=1');
      const data = await response.json();
      console.log( data.data)
      if (data && data.data && data.data.length > 0) {
        // Assuming the price is in the first item of the data array
        setPrice(data.data[0].price); // Adjust the key based on the actual response structure
      }
    } catch (error) {
      console.log('Error fetching price:', error);
    }
  };

  return (
    <>
      <div className="product mb-5">
        <div className="container ">
        <h2 class="hd">Product By </h2>
        <br />
          <div className="product-main  ">
            <Row
              className=" m-4 py-4  gy-3"
              style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.10)" }}
            >
              <Col xl={6} lg={8} md={12}>
                <Row className=" gy-3"> 
                  <Col className="d-flex flex-row gap-3">
                    <img
                      src="image/mrmintcoin.png"
                      style={{ width: 70, height: 70 }}
                      alt=""
                    />
                    <img
                      src="image/mrmint.png"
                      style={{
                        width: "170px",
                        height: "71px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </Col>
                  <Col 
                    style={{
                      borderLeft: "1px solid rgba(255, 255, 255, 0.10)",
                    }}
                  >
                    <small>MNT Price</small>
                    <div className="d-flex flex-row align-items-baseline flex-wrap">
                      <h4>$ {price}</h4>
                      {/* <p  >
                        <IoMdArrowDropup
                          style={{ color: "#52FF00", width: 20, height: 20 }}
                        />
                       <span style={{ color: "#52FF00" }}> 0.7%</span>
                        <img
                          src="image/trading.svg"
                          style={{ width: 20, height: 20, marginLeft:5 }}
                          alt=""
                        />
                      </p> */}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={6} lg={4} md={12} className="text-start text-md-end">
                <p className="mb-0">Follow us on social media</p>
                <div className="d-flex flex-row gap-2 justify-content-start justify-content-md-end align-items-center">
                  <a
                    target="_blank" href="https://www.facebook.com/profile.php?id=61557738151728"
              
                    rel="noopener noreferrer"
                  >
                    <FaFacebook style={{width:20 , height:20}} />
                  </a>
                  <a
                    target="_blank" href="https://www.instagram.com/sportsmint_"
                 
                    rel="noopener noreferrer"
                  >
                    <FaInstagram style={{width:20 , height:20}} />
                  </a>
                  <a
                    target="_blank" href="https://twitter.com/sportsmint_"
              
                    rel="noopener noreferrer"
                  >
                    <FaXTwitter style={{width:20 , height:20}} />
                  </a>
                  <a
                    target="_blank" href="https://www.linkedin.com/company/sports-mint"
                 
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin style={{width:20 , height:20}} />
                  </a>
                  <a
                    target="_blank" href="https://t.me/sportsmint"
               
                    rel="noopener noreferrer"
                  >
                    <BsTelegram style={{width:20 , height:20}} />
                  </a>
                </div>
              </Col>
            </Row>

            {/* SECTION2 */}
           
            <p className="m-4 pb-4" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.10)" , opacity:0.8 }}>Mr. Mint is revolutionizing Web 3.0 with SportsMint, a game-changing platform set to redefine sports fantasy. Leading the way to a decentralized future, we’re unlocking limitless possibilities. Join us and be part of this exciting journey!</p>
          
           {/* OUR PRODUCTS */}
           <div className="our-products p-4">
            <p className="text-md-start text-center p-small">Our Products</p>
            <div className="d-flex flex-row  justify-content-evenly justify-content-sm-between flex-wrap gap-3 ">
              <a className="d-flex " target="_blank" rel="noopener noreferrer" href="https://sportsmint.io/">  <img src="image/product01.svg" style={{maxWidth:"130px", height:"auto" , objectFit:"contain"}}  alt="" /></a>
              <a className="d-flex " target="_blank" rel="noopener noreferrer" href="https://stepmint.io/">  <img src="image/product02.svg"  alt="" style={{maxWidth:"130px", height:"auto" , objectFit:"contain"}} /></a>
              <a className="d-flex " target="_blank" rel="noopener noreferrer" href="https://mrmint.io/"><img src="image/product03.svg"  alt="" style={{maxWidth:"130px", height:"auto" , objectFit:"contain"}} /></a>
              <a className="d-flex " target="_blank" rel="noopener noreferrer" href="https://nft.mrmint.io/"><img src="image/product04.svg"  alt="" style={{maxWidth:"140px", height:"auto" , objectFit:"contain"}} /></a>
                
               <div className="d-flex flex-column">
               <small>Coming Soon</small>
               <img src="image/product05.png"  alt="" style={{maxWidth:"130px", height:"auto" , objectFit:"contain"}} />
               </div>
                <div className="d-flex flex-column">
                <small>Coming Soon</small>
                <img src="image/product06.png"  alt="" style={{maxWidth:"130px", height:"auto" , objectFit:"contain"}} />
                </div>
            </div>
           </div>

            {/* AWARDS */}

            <div className="awards mx-4 py-4 "  >
                <Row>
                    <Col lg={7}>
<p className="mb-2 p-small text-md-start text-center">Awards</p>
<Row className="d-flex flex-row flex-wrap justify-content-md-start justify-content-center mt-2">
<Col lg={5} md={6} sm={6} xs={6} >
<img src="image/award2.png" style={{width:"100%" ,height:"100%", objectFit:"contain"}} alt="" />

</Col>
<Col lg={5} md={6} sm={6} xs={6}>

<img src="image/award1.png" style={{width:"100%" ,height:"100%", objectFit:"contain"}} alt="" />
</Col>
    
   
</Row>
                    </Col>
                    <Col lg={5} className="d-flex flex-column justify-content-between">
<div>
<p className="my-3 p-small text-md-start text-center">MNT Listed On</p>
<Row className="gy-3 mb-3 ">
    <Col lg={6} md={6} sm={6} xs={6} >

    <a target="_blank" rel="noopener noreferrer" href="https://www.coinstore.com/spot/MNTUSDT?ts=1712937732921">    <img src="image/listing1.png" alt="" style={{objectFit:"contain", width:"100%", height:"100%", maxWidth:"180px"}}/></a>
    </Col>
    <Col lg={6} md={6} sm={6} xs={6}>
    <a target="_blank" rel="noopener noreferrer" href="https://skynet.certik.com/projects/mr-mint">    <img src="image/listing2.png" alt="" style={{objectFit:"contain", width:"100%", height:"100%", maxWidth:"180px"}}/></a>

    </Col>
    <Col lg={6} md={6} sm={6} xs={6}>
    
    <a target="_blank" rel="noopener noreferrer" href="https://www.coingecko.com/en/coins/mr-mint"><img src="image/listing3.png" alt="" style={{objectFit:"contain", width:"100%", height:"100%", maxWidth:"180px"}}/></a>
    </Col>
    <Col lg={6} md={6} sm={6} xs={6}>
    
    <a target="_blank" rel="noopener noreferrer" href="https://pancakeswap.finance/swap?inputCurrency=0x3e81Aa8d6813Ec9D7E6ddB4e523fb1601a0e86F3&outputCurrency=0x55d398326f99059fF775485246999027B3197955"><img src="image/listing4.png" alt="" style={{objectFit:"contain", width:"100%", height:"100%", maxWidth:"180px"}}/></a>
    </Col>
    <Col lg={6} md={6} sm={6} xs={6}>
    
    <a target="_blank" rel="noopener noreferrer" href="https://www.mexc.com/exchange/MRMINT_USDT"><img src="image/mexc.svg" alt="" style={{objectFit:"contain", width:"100%", height:"100%", maxWidth:"160px"}}/></a>
    </Col>
    <Col lg={6} md={6} sm={6} xs={6}>
    
    <div className="text-white why_presale text-sm-end text-end  ">
                                                <Button className="btn_uni px-4 py-2 w-100"> <a target="_blank" rel="noopener noreferrer" href="https://mrmint.io/"><img src="image/web.svg" alt="" className="me-2"/>Visit Website</a></Button>
                                                {/* <span className="heading_b px-5">Invest Now</span> */}
                                            </div>
    </Col>
</Row>
</div>

                                           
                    </Col>
                </Row>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
