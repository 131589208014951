import React from "react";
import '../../components/style/css/pages/Home/Banner.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import config from "../../config/config";
// Import Swiper styles
import 'swiper/css';
// import './styles.css';

import { EffectCoverflow } from 'swiper/modules';
import { Autoplay } from "swiper/modules";
import { Bounce } from "react-awesome-reveal";
import { Button, Col, Row } from "react-bootstrap";
import { IoMdArrowDropup } from "react-icons/io";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

// import required modules

const scrollToTarget = () => {
    const targetElement = document.getElementById('targetElement');

    if (targetElement) {
        window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
        });
    }
};

export default function HomeBanner() {
    return (
        <>
            {/* <ToastPopup /> */}
            {/* <Toaster /> */}
            <section className="home_banner">
                {/* <img className="m-back" src="image/background04.png" alt="" /> */}
                <div className="container hb">
                    <div className="banner_content">
                        <div className="row" >
                            <div className="d-flex  align-items-center justify-content-center ">
                              
                                <div className="d-flex flex-row">
                                   <Row className="gy-5">
                                    <Col lg={8} className="d-flex  align-items-center justify-content-start">
                                         <div className="text-center d-flex flex-column justify-content-center align-items-start">  
                                        <p className="m-stake" style={{fontSize:28}}>Stake in</p>
                                            <h1 className="main-header text-start"  > <span>SportsMint's</span> <br /> Billion-Dollar Vision</h1>

                                     
<br />
                                            <p className="text-start m-para" >Contribute your expertise to shape the future of interactive skill based sports entertainment</p>
<br />
<div className="text-white why_presale  ">
                                                <Button className="btn_uni px-5 py-2" style={{width:200, height:52}} 
                                                 onClick={scrollToTarget} >Start Staking</Button>
                                                {/* <span className="heading_b px-5">Invest Now</span> */}
                                            </div>

                                         
                                        </div>
                                    </Col>
                                    <Col lg={4}>
  <div className="d-flex justify-content-end align-items-center">
                                            <img src="image/background02.svg" style={{width:"100%", height:"100%"}}   alt="" />
                                        </div>
                                    </Col>
                                   </Row>
                                   
                                       
                                      
                                   
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                {/* <div className="mask"></div> */}
                {/* <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="content">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita incidunt pariatur praesentium debitis? Eius vel placeat obcaecati harum, voluptates deleniti commodi ratione doloribus, atque dignissimos officiis blanditiis. Voluptate ratione, necessitatibus rerum eius cupiditate sit quos reiciendis harum veritatis adipisci, expedita dolorem quaerat corporis possimus maxime aliquid? Esse minus eaque laudantium ipsum repellat soluta optio fugiat labore repellendus ex beatae, modi velit necessitatibus enim cumque accusantium nostrum eius consequatur dicta aliquam. Molestias eos perferendis fugit labore. Soluta aut dolorem perspiciatis voluptatem reprehenderit dolorum illum consectetur odio id possimus, nulla quia, facilis ducimus fugit enim maxime voluptate harum cum consequatur? Quas, beatae!
                            </div>
                            <div className="col-6"></div>
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}