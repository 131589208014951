import Web3 from "web3";
import web3Config from "./web3Config";

import config from "../../config/config";

import BigNumber from "bignumber.js";
import axios from "axios";
import { message } from "antd";

const USDTContractAddress = config.USDTContractAddress;
const MNTContractAddress = config.MNTContractAddress;


const USDTABI = config.USDTABI;

const SportsMintInvestorContractABI = config.SportsMintInvestorContractABI;
const SportsMintInvestorContractAddress =
  config.SportsMintInvestorContractAddress;

////New Runing Code
const validateProvider = async (walletProvider) => {
  try {
    walletProvider = await walletProvider;
    if (!walletProvider) {
      return {
        status: false,
        message: `Please connect your web3 wallet`,
      };
    }
    const web3 = new Web3(walletProvider);

    return {
      status: true,
      web3,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getChainId = async (walletProvider) => {
  const validate = await validateProvider(walletProvider);

  if (!validate.status) {
    return validate;
  }
  let web3 = validate.web3;

  let ccc = await web3.eth.getChainId();

  return parseInt(ccc);
};

export const getAddressValidation = async (walletAddress, walletProvider) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }
    let web3 = validate.web3;

    let isAddress = await web3.utils.isAddress(walletAddress);
    return isAddress;
  } catch (error) {
    console.error(error);
  }
};



export const findAddressVesting = async (walletAddress, walletProvider,) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );
    let getVestingSchedulesCountByBeneficiary = await sportsInvestorContract.methods.getVestingSchedulesCountByBeneficiary(walletAddress).call();
    getVestingSchedulesCountByBeneficiary = parseInt(getVestingSchedulesCountByBeneficiary)

    return { status: true, data: getVestingSchedulesCountByBeneficiary }
  } catch (err) {
    console.log(err)
    return { status: false, msg: err }
  }
}

export const getPurchaseInvestorPlanDetails = async (
  walletAddress,
  walletProvider,
  planAmount
) => {
  console.log("purchaseInvestorPlan");
  try {
    let planId = planAmount == 25 ? 0 : planAmount == 50 ? 1 : 2;

    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;


    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let decimals = 18;
    const getPayableAmount = await sportsInvestorContract.methods.getVestingAmountByPlanId(planId, walletAddress).call();
    console.log({ getPayableAmount })
    let usdtAmount = (parseInt(getPayableAmount[0]) / 10 ** parseInt(decimals)).toFixed(2);

    let MntAmount = parseInt(getPayableAmount[1]) / 10 ** parseInt(decimals);
    MntAmount = parseFloat((parseFloat(MntAmount) + 0.1).toFixed(2));

    let allocationMNTAmount = (parseInt(getPayableAmount[2]) / 10 ** parseInt(decimals)).toFixed(2);

    return {
      status: true,
      usdt: usdtAmount,
      mnt: MntAmount,
      allocation: allocationMNTAmount,
    }
  } catch (error) {
    console.error(error, "asdf");
    return {
      status: false,
      code: error.code,
      message: "Pocessing transaction: " + error.message,
    };
  }
}
export const purchaseInvestorPlan = async (
  walletAddress,
  walletProvider,
  planAmount,
  PaymentCoin
) => {
  console.log("purchaseInvestorPlan");
  try {
    let planId = planAmount == 25 ? 0 : planAmount == 50 ? 1 : 2;

    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let bnbBalance = await web3.eth.getBalance(walletAddress);

    if (bnbBalance <= 0) {
      return { status: false, message: "Insufficient BNB balance" };
    }
    let PaymentTokenAddress = PaymentCoin == 'MNT' ? MNTContractAddress : USDTContractAddress;
    let usdtContract = new web3.eth.Contract(USDTABI, PaymentTokenAddress);

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let decimals = await usdtContract.methods.decimals().call();
    const getPayableAmount = await sportsInvestorContract.methods.getVestingAmountByPlanId(planId, walletAddress).call();

    planAmount = parseInt(getPayableAmount[0]) / 10 ** parseInt(decimals);
    if (PaymentCoin == 'MNT') {
      planAmount = parseInt(getPayableAmount[1]) / 10 ** parseInt(decimals);
      planAmount = parseFloat((parseFloat(planAmount) + 0.1).toFixed(2));
    }

    let balance = await usdtContract.methods.balanceOf(walletAddress).call();
    console.log("1")

    const balanceFormatted = web3.utils.fromWei(balance.toString(), "ether");
    console.log(parseInt(balanceFormatted) <= planAmount)

    if (parseInt(balanceFormatted) <= planAmount) {
      return { status: false, message: `Insufficient ${PaymentCoin} balance` };
    }


    let allowance = await usdtContract.methods
      .allowance(walletAddress, SportsMintInvestorContractAddress)
      .call();
    const allowanceFormatted = web3.utils.fromWei(
      allowance.toString(),
      "ether"
    );

    if (parseFloat(allowanceFormatted) < parseFloat(planAmount)) {
      let amount = (planAmount * 10 ** parseInt(decimals)).toLocaleString(
        "fullwide",
        {
          useGrouping: false,
        }
      );
      let approvalResult;
      try {
        approvalResult = await approval(
          web3,
          amount,
          walletAddress,
          SportsMintInvestorContractAddress,
          usdtContract,
          PaymentTokenAddress
        );

        if (approvalResult.length > 0) {
          if (!approvalResult[0].status) {
            return { status: false, message: "User rejected approval" };
          }
        }
      } catch (error) {
        console.log(error);
        return { status: false, message: approvalResult.message };
      }
    }

    let allowance1 = await usdtContract.methods
      .allowance(walletAddress, SportsMintInvestorContractAddress)
      .call();
    const allowanceFormatted1 = web3.utils.fromWei(
      allowance1.toString(),
      "ether"
    );

    if (parseFloat(allowanceFormatted1) < parseFloat(planAmount)) {
      return { status: false, message: "Insufficient allowance." };
    }

    let currentTimestampInSeconds = Math.floor(Date.now() / 1000);
    currentTimestampInSeconds = parseInt(currentTimestampInSeconds) + 90;

    let amount = (planAmount * 10 ** parseInt(decimals)).toLocaleString(
      "fullwide",
      {
        useGrouping: false,
      }
    );

    let createVestingSchedule =
      await sportsInvestorContract.methods.createVestingSchedule(
        planId,
        PaymentTokenAddress
      );

    let encoded_tx = createVestingSchedule.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return {
        status: true,
        message:
          "The plan was purchased successfully. You can check it in the My Investments section..",
      };
    }
  } catch (error) {
    console.error(error, "asdf");
    return {
      status: false,
      code: error.code,
      message: "Pocessing transaction: " + error.message,
    };
  }
};

export const getParams = async (walletAddress, walletProvider) => {
  console.log("getParams");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let getVestingSchedulesCountByBeneficiary =
      await sportsInvestorContract.methods
        .getVestingSchedulesCountByBeneficiary(walletAddress)
        .call();
    getVestingSchedulesCountByBeneficiary = parseInt(
      getVestingSchedulesCountByBeneficiary
    );

    let totalMNT = new BigNumber(0);
    let totalUSDT = new BigNumber(0);
    let released = new BigNumber(0);

    for (let i = 0; i < getVestingSchedulesCountByBeneficiary; i++) {
      let getVestingScheduleByAddressAndIndex =
        await sportsInvestorContract.methods
          .getVestingScheduleByAddressAndIndex(walletAddress, i.toString())
          .call();

      const amountTotalBN = new BigNumber(
        getVestingScheduleByAddressAndIndex.amountTotal
      );
      const amountTotalDecimal = amountTotalBN.dividedBy(
        new BigNumber(10 ** 18)
      );

      totalMNT = totalMNT.plus(amountTotalDecimal);

      const totalUSDTBN = new BigNumber(
        getVestingScheduleByAddressAndIndex.usdtAmount
      );
      const totalUSDTDecimal = totalUSDTBN.dividedBy(new BigNumber(10 ** 18));

      totalUSDT = totalUSDT.plus(totalUSDTDecimal);

      const releasedBN = new BigNumber(
        getVestingScheduleByAddressAndIndex.released
      );
      const releasedDecimal = releasedBN.dividedBy(new BigNumber(10 ** 18));

      released = released.plus(releasedDecimal);
    }

    let transactionHashs = await fetchTransactionHashWithWalletAddress(
      web3,
      walletAddress
    );

    if (transactionHashs <= 0) {
      return {
        status: true,
        data: [{ totalMNT, totalUSDT, released }],
        history: [],
      };
    }

    let vestingSchedulesBytes = [];
    let getVestingSchedulesCount = await sportsInvestorContract.methods
      .getVestingSchedulesCount()
      .call();
    getVestingSchedulesCount = parseInt(getVestingSchedulesCount);

    let vestings = [];
    let count = 0;
    for (let i = 0; i < getVestingSchedulesCount; i++) {
      let getVestingIdAtIndex = await sportsInvestorContract.methods
        .getVestingIdAtIndex(i)
        .call();

      let computeReleasableAmount = await sportsInvestorContract.methods
        .computeReleasableAmount(getVestingIdAtIndex)
        .call();

      computeReleasableAmount = parseInt(computeReleasableAmount);
      computeReleasableAmount = (
        computeReleasableAmount /
        10 ** 18
      ).toLocaleString("fullwide", {
        useGrouping: false,
      });

      let getVestingSchedule = await sportsInvestorContract.methods
        .getVestingSchedule(getVestingIdAtIndex)
        .call();

      if (
        getVestingSchedule.beneficiary.toUpperCase() ==
        walletAddress.toUpperCase()
      ) {
        const startUnixTimestamp = parseInt(getVestingSchedule.start);
        const startDate = new Date(startUnixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds
        const endUnixTimestamp = parseInt(
          parseInt(getVestingSchedule.start) +
          parseInt(getVestingSchedule.duration)
        );
        const endDate = new Date(endUnixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
        let sclicePeriod = parseInt(
          parseInt(getVestingSchedule.start) +
          parseInt(getVestingSchedule.duration) +
          parseInt(getVestingSchedule.slicePeriod)
        );
        let remainingDays1 =
          sclicePeriod > currentTimestampInSeconds
            ? sclicePeriod - currentTimestampInSeconds
            : 0;
        let remainingDays = remainingDays1 > 86400 ? remainingDays1 / 86400 : 0;

        const usdtBN = new BigNumber(getVestingSchedule.usdtAmount);
        const usdtDecimal = usdtBN.dividedBy(new BigNumber(10 ** 18));

        let isLocked = true;
        if (remainingDays1 <= 0) {
          isLocked = false;
        }

        let historyArray = {
          isLocked,
          remainingDays,
          usdtDecimal,
          endDate: endDate.toDateString(),
          startDate: startDate.toDateString(),
          transactionHash: transactionHashs[count].transactionHash,
          releasedHistory: [],
          computeReleasableAmount,
        };

        vestings.push({
          vestingSchedulesID: getVestingIdAtIndex,
          transactionHistory: historyArray,
        });

        vestingSchedulesBytes.push(getVestingIdAtIndex);
        count++;
      }
    }

    let releasedData = await fetchReleasedTransactions(
      web3,
      vestingSchedulesBytes
    );

    for (let i = 0; i < vestings.length; i++) {
      for (let j = 0; j < releasedData.length; j++) {
        if (
          vestings[i].vestingSchedulesID.toUpperCase() ==
          releasedData[j].vestingSchedulesID.toUpperCase()
        ) {
          vestings[i].transactionHistory.releasedHistory.push(
            releasedData[j].data
          );
        }
      }
    }

    return {
      status: true,
      data: [{ totalMNT, totalUSDT, released }],
      history: vestings,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getParams2 = async (walletAddress, walletProvider) => {
  console.log("getParams");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let getVestingSchedulesCount = await sportsInvestorContract.methods
      .getVestingSchedulesCount()
      .call();
    getVestingSchedulesCount = parseInt(getVestingSchedulesCount);

    let totalMNT = new BigNumber(0);
    let totalUSDT = new BigNumber(0);
    let released = new BigNumber(0);

    let vestingsArray = [];
    let earnedAmount = 0;
    for (let i = 0; i < getVestingSchedulesCount; i++) {
      let getVestingIdAtIndex = await sportsInvestorContract.methods
        .getVestingIdAtIndex(i.toString())
        .call();

      let getVestingScheduleByAddressAndIndex =
        await sportsInvestorContract.methods
          .getVestingSchedule(getVestingIdAtIndex)
          .call();
      if (
        getVestingScheduleByAddressAndIndex.beneficiary.toUpperCase() ==
        walletAddress.toUpperCase()
      ) {
        const amountTotalBN = new BigNumber(
          getVestingScheduleByAddressAndIndex.amountTotal
        );
        const amountTotalDecimal = amountTotalBN.dividedBy(
          new BigNumber(10 ** 18)
        );

        totalMNT = totalMNT.plus(amountTotalDecimal);

        const totalUSDTBN = new BigNumber(
          getVestingScheduleByAddressAndIndex.usdtAmount
        );
        const totalUSDTDecimal = totalUSDTBN.dividedBy(new BigNumber(10 ** 18));
        totalUSDT = totalUSDT.plus(totalUSDTDecimal);

        let packagePurchased = parseInt(getVestingScheduleByAddressAndIndex.packagePurchased)

        vestingsArray.push({
          package:
            packagePurchased == 0
              ? "gold"
              : packagePurchased == 1
                ? "platinum"
                : "diamond",
          name:
            packagePurchased == 0
              ? "GOLD"
              : packagePurchased == 1
                ? "PLATINUM"
                : "DIAMOND",
          amount:
            packagePurchased == 0
              ? "25K"
              : packagePurchased == 1
                ? "50K"
                : "100K",
          vestingScheduledId: getVestingIdAtIndex
        });

        const releasedBN = new BigNumber(
          getVestingScheduleByAddressAndIndex.released
        );
        const releasedDecimal = releasedBN.dividedBy(new BigNumber(10 ** 18));

        let earnedAmountResult = await fetchEarnedTransactions3(web3, getVestingIdAtIndex)
        for (let xx = 0; xx < earnedAmountResult.length; xx++) {
          earnedAmount = parseFloat(earnedAmount) + parseFloat(earnedAmountResult[xx].earnedAmount)
        }
        released = released.plus(releasedDecimal);
      }
    }
    return {
      status: true,
      data: [{ totalMNT, totalUSDT, released, earnedAmount, packages: vestingsArray }],
    };
  } catch (error) {
    console.log(error);
  }
};

export const slotDetails = async (vestingId, walletProvider) => {
  console.log("slotDetails");
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;
    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let getVestingSchedule = await sportsInvestorContract.methods
      .getVestingSchedule(vestingId)
      .call();




    const amountTotalDecimal = parseFloat((parseInt(getVestingSchedule.amountTotal) / 10 ** 18).toFixed(4));
    let totalMNT = amountTotalDecimal;


    const totalUSDTDecimal = parseFloat((parseInt(getVestingSchedule.usdtAmount) / 10 ** 18).toFixed(4));
    let totalUSDT = totalUSDTDecimal;


    let packagePurchased = parseInt(getVestingSchedule.packagePurchased)

    let color =
      packagePurchased == 0
        ? "gold"
        : packagePurchased == 1
          ? "platinum"
          : "diamond";

    let name =
      packagePurchased == 0
        ? "GOLD"
        : packagePurchased == 1
          ? "PLATINUM"
          : "DIAMOND";

    let amount =
      packagePurchased == 0
        ? "25K"
        : packagePurchased == 1
          ? "50K"
          : "100K"


    const releasedDecimal = parseFloat((parseInt(getVestingSchedule.released) / 10 ** 18).toFixed(4));
    let released = releasedDecimal;

    const startUnixTimestamp = parseInt(getVestingSchedule.start);
    let startDate = new Date(startUnixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

    startDate = startDate.toLocaleString('en-US', {

      year: 'numeric', // "2024"
      month: 'short', // "May"
      day: '2-digit', // "22"
      // hour: '2-digit', // "07"
      // minute: '2-digit', // "03"
      // second: '2-digit', // "29"
      // hour12: false // 24-hour format
    });
    // const endUnixTimestamp = parseInt(
    //   parseInt(getVestingSchedule.start) +
    //   parseInt(getVestingSchedule.duration)
    // );
    const endUnixTimestamp = parseInt(
      parseInt(getVestingSchedule.cliff) 
    );
    let endDate = new Date(endUnixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

    endDate = endDate.toLocaleString('en-US', {

      year: 'numeric', // "2024"
      month: 'short', // "May"
      day: '2-digit', // "22"
      hour: '2-digit', // "07"
      minute: '2-digit', // "03"
      second: '2-digit', // "29"
      hour12: false // 24-hour format
    });

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    let claimAvailable = false;

    // let claimStarted = parseInt(
    //   parseInt(getVestingSchedule.start) +
    //   parseInt(getVestingSchedule.duration) +
    //   parseInt(getVestingSchedule.slicePeriod)
    // );
    let claimStarted = parseInt(
      parseInt(getVestingSchedule.cliff) +
      //parseInt(getVestingSchedule.duration) +
      parseInt(getVestingSchedule.slicePeriod)
    );

    let releasableAmount = 0;

    if (claimStarted <= currentTimestampInSeconds) {
      claimAvailable = true;
      let computeReleasableAmount = await sportsInvestorContract.methods.computeReleasableAmount(vestingId).call();

      releasableAmount = parseFloat((parseInt(computeReleasableAmount) / 10 ** 18).toFixed(4));
    }
    let releasedTT = released + releasableAmount;
    let upgradeAvailable = false;

    // let upgradeStarted = parseInt(
    //   parseInt(getVestingSchedule.start) +
    //   parseInt(getVestingSchedule.duration)
    // );

    let upgradeStarted = parseInt(
      parseInt(getVestingSchedule.cliff) +
      //parseInt(getVestingSchedule.duration) +
      parseInt(getVestingSchedule.slicePeriod)
    );

    if (upgradeStarted <= currentTimestampInSeconds && released <= 0) {
      upgradeAvailable = true
    }

    let releasedHistory = await fetchReleasedTransactions2(web3, vestingId, getVestingSchedule.beneficiary)

    let earnedHistory = await fetchEarnedTransactions2(web3, vestingId, getVestingSchedule.beneficiary)

    return {
      status: true,
      data: { totalMNT, color, name, amount, releasableAmount, claimAvailable, upgradeAvailable, endDate, startDate, released, releasedTT, totalUSDT, releasedHistory, earnedHistory },

    };
  } catch (error) {
    console.log(error);
    return {
      status: false,
      message: error.toString()
    }
  }
};


export const claimReleasableAmountMNT = async (
  walletAddress,
  walletProvider,
  vestingSchedulesID
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;
    let bnbBalance = await web3.eth.getBalance(walletAddress);

    if (bnbBalance <= 0) {
      return { status: false, message: "Insufficient BNB balance" };
    }

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let computeReleasableAmount = await sportsInvestorContract.methods
      .computeReleasableAmount(vestingSchedulesID)
      .call();

    let amount = computeReleasableAmount.toLocaleString("fullwide", {
      useGrouping: false,
    });
    let release = await sportsInvestorContract.methods.release(
      vestingSchedulesID);

    let encoded_tx = release.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return {
        status: true,
        message: `MNT claimed successfully.Please wait till the transaction not gets confirmed.`,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      code: error.code,
      message: "Pocessing transaction: " + error.message,
    };
  }
};

export const upgradeVestingDuration = async (
  walletAddress,
  walletProvider,
  vestingSchedulesID
) => {
  try {
    const validate = await validateProvider(walletProvider);

    if (!validate.status) {
      return validate;
    }

    let web3 = validate.web3;
    let bnbBalance = await web3.eth.getBalance(walletAddress);

    if (bnbBalance <= 0) {
      return { status: false, message: "Insufficient BNB balance" };
    }

    let sportsInvestorContract = new web3.eth.Contract(
      SportsMintInvestorContractABI,
      SportsMintInvestorContractAddress
    );

    let upgradeVestingDuration =
      await sportsInvestorContract.methods.updateVestingDuration(
        vestingSchedulesID
      );

    let encoded_tx = upgradeVestingDuration.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: SportsMintInvestorContractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (trx.transactionHash) {
      return {
        status: true,
        message: `Transaction successful. Please wait till transaction gets confim on blockchain`,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      status: false,
      code: error.code,
      message: "Pocessing transaction: " + error.message,
    };
  }
};

const fetchTransactionHashWithWalletAddress = async (web3, walletAddress) => {
  console.log("fetchTransactionHashWithWalletAddress");
  try {
    let transactionHashArray = [];
    let url = `${web3Config.API_URL}?module=logs&action=getLogs&address=${SportsMintInvestorContractAddress}&fromBlock=0&toBlock=latest&apikey=${web3Config.API_KEY}`;

    const apiResposne = await axiosAPI(url);

    if (apiResposne.success) {
      if (apiResposne.data.length > 0) {
        for (let i = 0; i < apiResposne.data.length; i++) {
          if (
            apiResposne.data[i].topics[0].toUpperCase() ==
            web3Config.TOPICS.toUpperCase()
          ) {
            let beneficiary = await web3.eth.abi.decodeParameters(
              ["address"],
              apiResposne.data[i].topics[1]
            );
            beneficiary = beneficiary["0"];

            if (beneficiary.toUpperCase() == walletAddress.toUpperCase()) {
              transactionHashArray.push({
                transactionHash: apiResposne.data[i].transactionHash,
              });
            }
          }
        }
      }
    }
    return transactionHashArray;
  } catch (error) {
    console.log(error);
  }
};

const fetchReleasedTransactions = async (web3, vestingSchedulesBytes) => {
  console.log("fetchReleasedTransactions");
  try {
    let returnData = [];
    if (vestingSchedulesBytes.length > 0) {
      for (let k = 0; k < vestingSchedulesBytes.length; k++) {
        returnData.push({
          vestingSchedulesID: vestingSchedulesBytes[k],
          data: [],
        });
      }
    }

    let url = `${web3Config.API_URL}?module=logs&action=getLogs&address=${SportsMintInvestorContractAddress}&fromBlock=0&toBlock=latest&
      apikey=${web3Config.API_KEY}`;

    const apiResposne = await axiosAPI(url);

    if (apiResposne.success) {
      if (apiResposne.data.length > 0) {
        for (let i = 0; i < apiResposne.data.length; i++) {
          if (
            apiResposne.data[i].topics[0].toUpperCase() ==
            web3Config.TOPICSRELEASED.toUpperCase()
          ) {
            let vestingSchedulesID = await web3.eth.abi.decodeParameters(
              ["bytes32"],
              apiResposne.data[i].topics[1]
            );

            for (let j = 0; j < returnData.length; j++) {
              if (
                vestingSchedulesID["0"].toUpperCase() ==
                returnData[j].vestingSchedulesID.toUpperCase()
              ) {
                let amount = await web3.eth.abi.decodeParameters(
                  ["uint256"],
                  apiResposne.data[i].data
                );
                //const releasedBN = new BigNumber(amount["0"]);
                // const releasedDecimal = releasedBN.dividedBy(new BigNumber(10 ** 18));

                const releasedBN = parseInt(amount["0"]);
                const releasedDecimal = (releasedBN / 10 ** 18).toLocaleString(
                  "fullwide",
                  {
                    useGrouping: false,
                  }
                );

                let date = apiResposne.data[i].timeStamp;
                date = await web3.utils.hexToNumber(date);
                date = new Date(date * 1000);
                date = date.toDateString();

                returnData[j].data.push({
                  transactionHash: apiResposne.data[i].transactionHash,
                  releasedAmount: releasedDecimal,
                  date,
                  status: "Completed",
                });
              }
            }
          }
        }
      }
    }
    return returnData;
  } catch (error) {
    console.log(error);
  }
};


const fetchReleasedTransactions2 = async (web3, vestingSchedulesByte, userWallet) => {
  console.log("fetchReleasedTransactions2");
  try {
    let returnData = [];

    const hexAddress = web3.utils.padLeft(userWallet.toLowerCase(), 64);
    let url = `${web3Config.API_URL}?module=logs&action=getLogs&address=${SportsMintInvestorContractAddress}&fromBlock=0&toBlock=latest&topic0=${web3Config.TOPICSRELEASED}&topic1=${hexAddress}&topic2=${vestingSchedulesByte}&apikey=${web3Config.API_KEY}`;

    const apiResposne = await axiosAPI(url);

    if (apiResposne.success) {

      for (let i = 0; i < apiResposne.data.length; i++) {


        let amount = await web3.eth.abi.decodeParameters(
          ["uint256"],
          apiResposne.data[i].data
        );

        const releasedBN = parseInt(amount["0"]);
        const releasedDecimal = parseFloat(releasedBN / 10 ** 18).toFixed(4)

        let date = apiResposne.data[i].timeStamp;
        date = await web3.utils.hexToNumber(date);
        date = new Date(date * 1000);
        date = date.toLocaleString('en-US', {
          year: 'numeric', // "2024"
          month: 'short', // "May"
          day: '2-digit', // "22"
        });
        returnData.push({
          transactionHash: apiResposne.data[i].transactionHash,
          releasedAmount: releasedDecimal,
          date,
          status: "Completed",
        });
      }
    }
    return returnData;
  } catch (error) {
    console.log(error);
  }
};


const fetchEarnedTransactions2 = async (web3, vestingSchedulesByte, userWallet) => {

  try {
    let returnData = [];
    const hexAddress = web3.utils.padLeft(userWallet.toLowerCase(), 64);
    let url = `${web3Config.API_URL}?module=logs&action=getLogs&address=${SportsMintInvestorContractAddress}&fromBlock=0&toBlock=latest&topic0=${web3Config.TOPICSEARNED}&topic1=${hexAddress}&topic2=${vestingSchedulesByte}&apikey=${web3Config.API_KEY}`;

    const apiResposne = await axiosAPI(url);

    if (apiResposne.success) {
      for (let i = 0; i < apiResposne.data.length; i++) {

        let amount = await web3.eth.abi.decodeParameters(
          ["uint256", "uint256"],
          apiResposne.data[i].data
        );
        const earnedBN = parseInt(amount["0"]);
        const earnedDecimal = parseFloat(earnedBN / 10 ** 18).toFixed(4)
        let date = apiResposne.data[i].timeStamp;
        date = await web3.utils.hexToNumber(date);
        date = new Date(date * 1000);
        date = date.toLocaleString('en-US', {
          year: 'numeric', // "2024"
          month: 'short', // "May"
          day: '2-digit', // "22"
        });

        returnData.push({
          transactionHash: apiResposne.data[i].transactionHash,
          earnedAmount: earnedDecimal,
          date,
          status: "Completed",
        });
      }
    }
    return returnData;
  } catch (error) {
    console.log(error);
  }
};

const fetchEarnedTransactions3 = async (web3, vestingSchedulesByte) => {
  console.log("fetchEarnedTransactions3");
  try {
    let returnData = [];


    let url = `${web3Config.API_URL}?module=logs&action=getLogs&address=${SportsMintInvestorContractAddress}&fromBlock=0&toBlock=latest&
      apikey=${web3Config.API_KEY}`;

    const apiResposne = await axiosAPI(url);

    if (apiResposne.success) {
      if (apiResposne.data.length > 0) {
        for (let i = 0; i < apiResposne.data.length; i++) {
          if (
            apiResposne.data[i].topics[0].toUpperCase() ==
            web3Config.TOPICSEARNED.toUpperCase()
          ) {
            let vestingSchedulesID = await web3.eth.abi.decodeParameters(
              ["bytes32"],
              apiResposne.data[i].topics[1]
            );
            console.log(vestingSchedulesID)

            if (
              vestingSchedulesID["0"].toUpperCase() ==
              vestingSchedulesByte.toUpperCase()
            ) {
              let amount = await web3.eth.abi.decodeParameters(
                ["uint256"],
                apiResposne.data[i].topics[2]
              );
              const earnedBN = parseInt(amount["0"]);
              const earnedDecimal = (earnedBN / 10 ** 18).toLocaleString(
                "fullwide",
                {
                  useGrouping: false,
                }
              );

              let date = apiResposne.data[i].timeStamp;
              date = await web3.utils.hexToNumber(date);
              date = new Date(date * 1000);
              date = date.toDateString();

              returnData.push({

                earnedAmount: earnedDecimal,

              });
            }

          }
        }
      }
    }
    return returnData;
  } catch (error) {
    console.log(error);
  }
};

const axiosAPI = async (url) => {
  console.log("axios API call");
  try {
    const response = await axios.get(url);
    if (parseInt(response.data.status) === 1) {
      return {
        success: true,
        data: response.data.result,
      };
    } else {
      return {
        success: false,
        msg: response.data.result,
      };
    }
  } catch (error) {
    console.log("axios api error");
    throw error;
  }
};
const approval = async (
  web3,
  amount,
  walletAddress,
  address,
  contract,
  contractAddress
) => {
  try {
    let approvalResult = await contract.methods.approve(address, amount);

    let encoded_tx = approvalResult.encodeABI();

    let gasPrice = await web3.eth.getGasPrice();

    let gasLimit = await web3.eth.estimateGas({
      gasPrice: web3.utils.toHex(gasPrice),
      to: contractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    let trx = await web3.eth.sendTransaction({
      gasPrice: web3.utils.toHex(gasPrice),
      gas: web3.utils.toHex(gasLimit),
      to: contractAddress,
      from: walletAddress,
      data: encoded_tx,
    });

    if (!trx.transactionHash) {
      return [{ status: false, message: "Approval failed." }];
    }
    return [{ status: true }];
  } catch (error) {
    return [{ status: false, message: error }];
  }
};
