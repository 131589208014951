import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import DataTable, { createTheme } from "react-data-table-component";

import DashboardHeader from "./header/Header";
import { useEffect, useState } from "react";
import config from "../../config/config";
import { useAccount } from "wagmi";
import "./css/myslot.css";
import "./css/myinvestment.css";
import "../../assets/style/Faqs.css";
import { IoIosArrowRoundBack } from "react-icons/io";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import Cardbg2 from "./Icon/Card_bg2";

import Check from "./Icon/Check";
import Cardbg from "./Icon/Card_bg";
import { purchaseInvestorPlan, slotDetails } from "../Web3/Connector";

import {
    getParams2,
    claimReleasableAmountMNT,
    upgradeVestingDuration,
} from "../Web3/Connector";
import toast, { Toaster } from "react-hot-toast";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Accordion,
    Modal,
} from "react-bootstrap";
import SidebarPro from "./sidebar/Sidebar";
import {
    Web3Modal,
    Web3Button,
    useWeb3Modal,
    Web3ModalProps,
} from "@web3modal/wagmi/react";
import { EmptyInvesmentDetailBox } from "./EmptyboxLoader";
import { Loader } from "./Loader";
import { Loader2 } from "./Loader2";

export default function Investmentdetail(props) {
    const { vestingId } = useParams();
    const [activeCard, setActiveCard] = useState("");
    const navigate = useNavigate();
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    if (!isConnected) {
        navigate(`${config.baseUrl}`);
    }
    const { open } = useWeb3Modal();
    const [transactionHistoryActiveTab, setTransactionHistoryActiveTab] = useState([]);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [visibleDiv, setVisibleDiv] = useState(1);

    const handleNextClick = (nextDiv) => {
        setVisibleDiv(nextDiv);
    };

    const handleBackClick = (previousDiv) => {
        setVisibleDiv(previousDiv);
    };
    const investMethod = async (plan, cardName) => {
        if (!isConnected) {
            open();
            return;
        }
        setActiveCard(cardName);
        let result = await purchaseInvestorPlan(
            address,
            _useWeb3ModalProvider,
            plan
        );
        if (result.status) {
            toast.success(result.message);
        } else {
            toast.error(result.message);
        }
        setActiveCard("");
    };
    const [ClubData, setClubData] = useState([]);

    const columns = [
        {
            name: "Claim Amount",
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <div className="me-2">
                        <img src="coin.png" alt="" width={`18px`} />
                    </div>
                    <div className="d-flex">${row.releasedAmount}MNT</div>
                </div>
            ),
        },
        {
            name: "Time",
            selector: (row) => row.date,
        },
        {
            name: "Status",
            // selector: row => row.status,
            cell: (row) => (
                <div className="d-flex align-items-center">
                    <button className="status_btn btn btn-sm">{row.status}</button>
                </div>
            ),
            center: true,
        },

        {
            name: "TNX Hash",
            cell: (row) => (
                <>
                    {row.transactionHash.toString().substring(0, 6)}...
                    {row.transactionHash
                        .toString()
                        .substring(
                            row.transactionHash.length - 6,
                            row.transactionHash.length
                        )}
                </>
            ),
        },
    ];

    const tableCustomStyles = {
        headRow: {
            style: {
                color: "rgb(219, 219, 219)",
                background:
                    "linear-gradient(90deg, rgba(5, 214, 217, 0.20) 0.3%, rgba(249, 7, 252, 0.20) 99.65%)",
                fontSize: "18px",
            },
        },
        rows: {
            style: {
                color: "rgb(219, 219, 219)",
                backgroundColor: "rgba(23, 25, 28, 1)",
                fontSize: "16px",
            },
        },
        pagination: {
            color: "rgb(219, 219, 219)",
            background: "white",
        },
    };
    createTheme(
        "solarized",
        {
            text: {
                primary: "#268bd2",
                secondary: "rgba(215, 215, 215, 0.32)",
            },
            background: {
                default: "#000",
            },
            context: {
                background: "#cb4b16",
                text: "#FFFFFF",
            },
            divider: {
                default: "#6b6a6b",
            },
            action: {
                button: "rgba(0,0,0,.54)",
                hover: "rgba(0,0,0,.08)",
                disabled: "rgba(0,0,0,.12)",
            },
        },
        "dark"
    );

    const { collapsed, setCollapsed } = props;

    // match height code start

    const HeightMatch = () => {
        const hMtch = document.getElementsByClassName("h_match_myclub");
        const ary = [];
        for (let i = 0; i < hMtch.length; i++) {
            ary.push(hMtch[i].offsetHeight);
        }
        let max = Math.max(...ary);

        for (let i = 0; i < hMtch.length; i++) {
            hMtch[i].style.height = max + "px";
        }
    };

    useEffect(() => {
        return HeightMatch();
    }, [ClubData.length ? true : false]);

    const _useWeb3ModalProvider = Web3ModalProvider();

    const [params, setParams] = useState([]);
    const [packages, setPackages] = useState([]);
    const [openNote, setOpenNote] = useState(true);
    const [loader, setLoader] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);
    const [cardDetails, setCardDetails] = useState([]);
    const [processStart, setProcessStart] = useState(false)
    const [tableData, setTableData] = useState({ released: 0, claimAvailable: false, releasableAmount: 0, upgradeAvailable: false })
    const [upgradeModalShow, setUpgradeModalShow] = useState(false);
    const [claimModalShow, setClaimModalShow] = useState(false);

    const [isProviderLoaded, setisProviderLoaded] = useState(false);


    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3ModalProvider.then(data => {
                if (data) {
                    setisProviderLoaded(true);
                    getSlotdetails();
                }
            })
        }
    }, [_useWeb3ModalProvider]);


    const getSlotdetails = async () => {
        try {
            if (isConnected) {
                setLoader(true);
                let result = await slotDetails(vestingId, _useWeb3ModalProvider);
                if (result.status) {
                    setCardDetails(result.data);
                    let released = parseFloat(result.data.released);
                    let claimAvailable = result.data.claimAvailable;
                    let upgradeAvailable = result.data.upgradeAvailable;
                    let releasableAmount = result.data.releasableAmount;
                    setTableData({ released, claimAvailable, releasableAmount, upgradeAvailable })
                }
                setLoader(false);
                setOpenNote(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const claimVestingAmount = async () => {
        setClaimModalShow(true);
    }

    const upgradeVestingAmount = async () => {
        setUpgradeModalShow(true);
    }


    const upgradeVesting = async () => {
        try {
            setLoaderButton(true)
            let result = await upgradeVestingDuration(
                address,
                _useWeb3ModalProvider,
                vestingId
            );
            if (result.status) {
                toast.success(result.message);
                setLoaderButton(false)
                setUpgradeModalShow(false)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                toast.error(result.message);
                setLoaderButton(false)
                setUpgradeModalShow(false)
            }
        } catch (error) {
            toast.error(error.toString());
            setUpgradeModalShow(false)
        }
    };

    const claimReleasableAmount = async () => {
        try {
            setLoaderButton(true)
            let result = await claimReleasableAmountMNT(
                address,
                _useWeb3ModalProvider,
                vestingId
            );
            if (result.status) {
                toast.success(result.message);
                setLoaderButton(false)
                setClaimModalShow(false)
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else {
                toast.error(result.message);
                setLoaderButton(false)
                setClaimModalShow(false)
            }

        } catch (error) {
            toast.error(error.toString());
            setClaimModalShow(false)
        }
    };

    if (isDisconnected) {
        navigate(`${config.baseUrl}`);
    }
    const columns2 = [
        {
            name: "Amount",

            cell: (row) => (
                <div className="staking-table-button " style={{ width: "150px" }}>
                    <p className="mb-0">
                        {" "}
                        <img
                            src="image/mrmintcoin.png"
                            width={20}
                            height={20}
                            style={{ marginRight: 10 }}
                            alt=""
                        />
                        ${row.amount} MNT
                    </p>
                </div>
            ),
            width: "25%",
        },
        {
            name: "Time",
            selector: (row) => row.time,
            width: "25%",
        },
        {
            name: "Status",

            cell: (row) => (
                <div
                    className="staking-table-button py-2"
                    style={{
                        width: "150px",
                        borderRadius: "5px",
                        backgroundColor:
                            row.Status === "Completed"
                                ? "rgba(115, 197, 143, 0.20)"
                                : "rgba(243, 78, 78, 0.20)",
                    }}
                >
                    <p className="mb-0">{row.Status}</p>
                </div>
            ),
            width: "25%",
        },

        {
            name: "Tx Hash",
            selector: (row) => row.hash,
        },
    ];
    const vesttable = [
        {
            name: "Start Date",
            selector: (row) => cardDetails?.startDate,
        },
        {
            name: "End Date",
            selector: (row) => cardDetails?.endDate,
        },
        {
            name: "Released",
            selector: (row) => `${row.released} MNT`,

        },
        {
            name: "Withdrawable",
            selector: (row) => `${row.releasableAmount} MNT`,

        },
        {
            name: "Action",

            cell: (row) => (
                <div className="d-flex gap-3 w-auto" >
                    <button onClick={() => claimVestingAmount()} className="btn_uni btn px-3 py-1 " style={{ width: 90 }} disabled={!row.claimAvailable || row.releasableAmount == 0}>Claim</button>
                    <button onClick={() => upgradeVestingAmount()} className="btn-primary btn px-3 py-1 bnt_pri_uni " style={{ width: 90 }} disabled={!row.upgradeAvailable}>Upgrade</button>
                </div>
            ),
        },


    ];

    const ReleasedHistoryTable = [{
        name: "#",
        selector: (row, index) => index + 1,
    },
    {
        name: "CLAIMED",
        selector: (row) => `${row.releasedAmount} MNT`,
    },
    {
        name: "DATE",
        selector: (row) => row.date,
    },
    {
        name: "HASH",
        cell: (row) => (
            <a
                href={`${config.blockchainUrl}${row.transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {row.transactionHash
                    .toString()
                    .substring(0, 10)}
                ...
                {row.transactionHash
                    .toString()
                    .substring(
                        row.transactionHash
                            .length - 10,
                        row.transactionHash
                            .length
                    )}
            </a>
        ),
    },
    ];

    const EarnedHistoryTable = [{
        name: "#",
        selector: (row, index) => index + 1,
    },
    {
        name: "EARNED",
        selector: (row) => `${row.earnedAmount} MNT`,
    },
    {
        name: "DATE",
        selector: (row) => row.date,
    },
    {
        name: "HASH",
        cell: (row) => (
            <a
                href={`${config.blockchainUrl}${row.transactionHash}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {row.transactionHash
                    .toString()
                    .substring(0, 10)}
                ...
                {row.transactionHash
                    .toString()
                    .substring(
                        row.transactionHash
                            .length - 10,
                        row.transactionHash
                            .length
                    )}
            </a>
        ),
    },
    ];

    const customStyles2 = {
        noData: {
		style: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: "white",
			backgroundColor: "transparent",
		},
	},
        rows: {
            style: {
                minHeight: "62px", // override the row height
                backgroundColor: "transparent",
                color: "white",
                paddingLeft: "10px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "rgba(102, 104, 206, 0.303)",
                borderRadius: "16px 16px 0px 0px",
                color: "white",

                paddingLeft: "10px",
            },
            denseStyle: {
                minHeight: "32px",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
            },
        },
        table: {
            style: {
                color: "white",
                backgroundColor: "transparent",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                backgroundColor: "transparent",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                borderBottom: "1px solid #A5A5A523",
            },
        },
        headeRow: {
            style: {
                borderBottom: "1px solid #A5A5A5",
                backgroundColor: "red",
            },
        },
        pagination: {
            style: {
                color: "white",
                fontSize: "13px",
                minHeight: "56px",
                backgroundColor: "transparent",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
            },
            pageButtonsStyle: {
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                padding: "8px",
                margin: "px",
                cursor: "pointer",
                transition: "0.4s",
                color: "white",
                fill: "white",
                backgroundColor: "transparent",
                "&:disabled": {
                    cursor: "unset",
                    color: "white",
                    fill: "white",
                },
            },
        },
    };
    const veststyle = {
        noData: {
		style: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: "white",
			backgroundColor: "transparent",
		},
	},
        rows: {
            style: {
                minHeight: "62px", // override the row height
                backgroundColor: "transparent",
                color: "white",
                paddingLeft: "10px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "rgba(102, 104, 206, 0.303)",
                borderRadius: "16px 16px 0px 0px",
                color: "white",

                paddingLeft: "10px",
            },
            denseStyle: {
                minHeight: "32px",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px", // override the cell padding for head cells
                paddingRight: "8px",

                fontSize: "16px",
                display: "flex",
                justifyContent: "start",
            },
        },
        table: {
            style: {
                color: "white",
                backgroundColor: "transparent",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px", // override the cell padding for data cells
                paddingRight: "8px",
                backgroundColor: "transparent",
                fontSize: "16px",
                display: "flex",
                justifyContent: "start",
                textAlign: "start",
                borderBottom: "1px solid #A5A5A523",
            },
        },
        headeRow: {
            style: {
                borderBottom: "1px solid #A5A5A5",
                backgroundColor: "red",
            },
        },
        pagination: {
            style: {
                color: "white",
                fontSize: "13px",
                minHeight: "56px",
                backgroundColor: "transparent",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
            },
            pageButtonsStyle: {
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                padding: "8px",
                margin: "px",
                cursor: "pointer",
                transition: "0.4s",
                color: "white",
                fill: "white",
                backgroundColor: "transparent",
                "&:disabled": {
                    cursor: "unset",
                    color: "white",
                    fill: "white",
                },
            },
        },
    };

    const [activeLink, setActiveLink] = useState('RELEASED');


    return (
        <>
            <div className="dashboard color-container">
                <div className="c1"></div>
                <div className="investor-c2"></div>
                <div className="investor-c3"></div>
                <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
                <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
                    <DashboardHeader
                        className={collapsed ? "marginL25" : "marginL250"}
                        collapsed={collapsed}
                        setCollapsed={setCollapsed}
                    />
                    <div className="dashboard_home mt-5 mx-3">
                        <section className="mb-5 px-1 px-sm-5 ">
                            <Row>{console.log({ cardDetails })}
                                <div className="newdashboard">
                                    <div className="section01">
                                        <div className={`invest-details-${cardDetails?.color || 'gold'}`}>
                                            <h2 className=" text-start hd mb-4">Investment Details</h2>
                                         
                                            <Row className="gx-5 px-3 ">
                                            {!cardDetails?.name ?
                                                      <Col lg={5} >
                                                      <Loader2   />
                                                      </Col>
                                                        :
                                                <Col
                                                    className={`invest-details-${cardDetails?.color || 'gold'}-card px-xs-5  py-4`}
                                                    lg={5}
                                                >
                                                  
                                                  <div className="mb-2 d-flex flex-row flex-wrap justify-content-between">
                                                                <h4 className={`invest-${cardDetails?.color}`}>
                                                                    {cardDetails?.name}
                                                                </h4>
                                                                <div
                                                                    onClick={() => navigate(-1)}
                                                                    className="mb-2 d-flex flex-row flex-wrap justify-content-center align-items-center"
                                                                >
                                                                    <IoIosArrowRoundBack />
                                                                    Back
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-row justify-content-between align-items-baseline flex-wrap gap-3">
                                                                <h1 className={`invest-${cardDetails?.color}-price`}>
                                                                    ${cardDetails?.amount}
                                                                </h1>
                                                                <h5>Invested</h5>
                                                            </div>
                                                            <hr />
                                                            <div className="invest d-flex flex-row flex-wrap gap-3 align-items-center">
                                                                <img
                                                                    src="image/mrmintcoin.png"
                                                                    style={{
                                                                        width: 64,
                                                                        height: "64px",
                                                                        objectFit: "contain",
                                                                    }}
                                                                    alt=""
                                                                />
                                                                <div>
                                                                    <h2 className="dashboard-h2 text-start">
                                                                        &nbsp;
                                                                        {parseFloat(cardDetails?.totalMNT).toFixed(2)} <span>MNT</span>
                                                                    </h2>
                                                                    <p className="dashboard-p text-start">
                                                                        <span>Invested</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                   
                                                </Col>
                                            }
                                                <Col lg={7}>
                                                    <div
                                                        className="pt-3 mb-4"
                                                        style={{
                                                            borderBottom:
                                                                "1px solid rgba(255, 255, 255, 0.10)",
                                                        }}
                                                    >
                                                        <h3 className={`mb-3 include-${cardDetails?.color}-h3`}>
                                                            Includes:
                                                        </h3>
                                                    </div>
                                                    <ul className="list-unstyled invetsment-details-ul">
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            Investors allotted MNT tokens equal to
                                                            investment at current rate.
                                                        </li>
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            5% of tokens released from 25th month,
                                                            must be claimed.
                                                        </li>
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            10% revenue shared with each group from
                                                            the launch month.
                                                        </li>
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            Option to renew investment in 23rd month
                                                            for continued revenue sharing.
                                                        </li>
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            Revenue share credited monthly in MNT
                                                            tokens.
                                                        </li>
                                                        <li>
                                                            <img
                                                                src="image/silveranchor.svg"
                                                                className="me-2"
                                                                alt=""
                                                            />
                                                            Allotted tokens non-transferable.
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="section02 investor-table  ">
                                        <h4 className=" text-start hd mb-sm-3 mb-4 ">Vesting Detail</h4>
                                        <div>
                                            <DataTable
                                                columns={vesttable}
                                                data={[tableData]}
                                                customStyles={veststyle}
                                            />
                                        </div>


                                    </div>

                                    <br />
                                    <div className="section02 investor-table ">
                                        <h4 className=" text-start hd mb-sm-3">Transactions History</h4>

                                        <div className="text-end d-flex flex-row gap-5 justify-content-md-end">

                                            <a

                                                className={`dashboard-option cool-link ${activeLink === 'RELEASED' ? 'active' : ''}`}
                                                onClick={() => setActiveLink('RELEASED')}
                                            >
                                                RELEASED
                                            </a>
                                            <a

                                                className={`dashboard-option cool-link ${activeLink === 'REVENUE' ? 'active' : ''}`}
                                                onClick={() => setActiveLink('REVENUE')}
                                            >
                                                REVENUE
                                            </a>
                                        </div>
                                        {activeLink === 'REVENUE' ?
                                            <DataTable
                                                columns={EarnedHistoryTable}
                                                data={cardDetails.earnedHistory}
                                                customStyles={customStyles2}
                                                pagination
                                            />
                                            :
                                            <DataTable
                                                columns={ReleasedHistoryTable}
                                                data={cardDetails.releasedHistory}
                                                customStyles={customStyles2}
                                                pagination
                                            />
                                        }
                                    </div>
                                </div>
                            </Row>
                        </section>
                    </div>
                </div>
            </div>

            <Modal show={claimModalShow} className="niceModal">
                <Modal.Header className="border-bottom p-3 px-3" >
                    <Modal.Title className="text-center">Confirm Claim </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 px-3">
                    <div className="text-center">
                        <p className="mb-4">
                            Are you sure you want to claim reward. Once you start claiming
                            reward ,you were not able to upgrade.{" "}
                        </p>
                        {loaderButton ?
                            <div>
                                <Button variant="secondary" >
                                    Please wait...
                                </Button>
                            </div>
                            :
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        claimReleasableAmount();
                                    }}
                                    className="me-2"
                                >
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={() => setClaimModalShow(false)}>
                                    No
                                </Button>
                            </div>}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={upgradeModalShow} className="niceModal">
                <Modal.Header className="border-bottom p-3 px-3" >
                    <Modal.Title className="text-center">Confirm Upgrade </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 px-3">
                    <div className="text-center">
                        <p className="mb-4">
                            Are you sure you want to upgrade locking duration. If you upgrade
                            your locking duration you will be not able to claim reward till
                            end of new locking period{" "}
                        </p>
                        {loaderButton ?
                            <div>
                                <Button variant="secondary" >
                                    Please wait...
                                </Button>
                            </div>
                            :
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        upgradeVesting();
                                    }}
                                    className="me-2"
                                >
                                    Yes
                                </Button>
                                <Button variant="secondary" onClick={() => setUpgradeModalShow(false)}>
                                    No
                                </Button>
                            </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
