module.exports = {
  mntContract: "0x0bc8cBd41407F14982EEA91c3c00BEc8810E3F0d",
  projectId: "f5977011bdf8f1a31a1ea68aa2d4a606",
  depositContractAdress: "0xf82b6dad2d7fdc6e8e5fa1d8f12be977262e8138",
  NETWORK: "BSC Testnet",
  CHAIN: "97",
  adminAddress: "0x575fBccf7966347C1e299921979c707Dd5cd5941",
  testneturl: "https://testnet.bscscan.com/tx/",
  API_URL: "https://api-testnet.bscscan.com/api", //"https://api-testnet.bscscan.com/",
  API_KEY: "FAPA5R11WKVWSBM8VVC1T8UGJ2FD12V2NQ", //"4EDEUFZQ3SBUXJJSHY16ICAT8PZPGW832F",
  TOPICS: "0xe6e3c95437b23cf65c62fa7144ed5984247c6bc6ae33c3c677f5f4523430b12c",
  TOPICSRELEASED:
    "0x9656a0d577d6bcc25d40e645dcb56858a30bc9aac157721bbac035dcb29e57c7",
    TOPICSEARNED:
    "0xb7781eaa397f4d4ffb13f314ceda6f0112b5215190d7f5f4e8811fbd30412828",
};
