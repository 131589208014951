import { Col } from "react-bootstrap"
import { ShimmerThumbnail } from "react-shimmer-effects"

export const EmptyInvesmentBox = ({ count }) => {
    return (
        <>
            {Array(count).fill().map((a, i) => (
                <Col lg={6}>
                    {/* <div className={` invest-card px-md-5 px-3 py-4`}> */}
                    <ShimmerThumbnail height={170} />
                    {/* </div> */}
                </Col>
            ))}
        </>
    )
}
export const EmptyInvesmentDetailBox = ({ count }) => {
    return (
        <>
            <ShimmerThumbnail height={50} />
            <ShimmerThumbnail height={50} />
            <ShimmerThumbnail height={30} />
            <ShimmerThumbnail height={30} />
        </>
    )
}
export const EmptyDashboardBalanceBox = ({ count }) => {
    return (
        <>
            <ShimmerThumbnail height={50} />
        </>
    )
}