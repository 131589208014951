import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import '../style/css/layout/FooterBanner.css'
import config from "../../config/config";
import { useNavigate } from 'react-router-dom';

export default function BannerFooter() {
    const navigate = useNavigate();

    const scrollToTarget = () => {
        if (window.location.pathname === `${config.baseUrl}whyus`) {
            // Redirect to another page and scroll to a specific section (e.g., /about#targetSection)
            navigate(`${config.baseUrl}`, { replace: true });
            // Smooth scroll after navigating
           
            const targetElement = document.getElementById('targetElement');
        
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        } else {
            // Scroll to a target element on the same page
            const targetElement = document.getElementById('targetElement');
        
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    };
    
    //{window.location.pathname === `${config.baseUrl}myInvestment`}
    return (
        <>
        <div className="px-3">
        <section className="banner_footer  container py-4">
            <div className="footer-banner-bg"></div>
           <div className="absolute">
           <img src="image/coins.png" className="footer-banner-img" alt="" />
           </div>
            <div className="p-sm-5 p-4  footer-banner-content d-flex flex-column align-items-md-start align-items-center gap-2 ">
                                <h5 className="banner-footer-h4">Transform Your Wealth with Customized Staking Solutions </h5>
                                <h2 className="banner-footer-h2">Engage, Stake, and Succeed with <span>Sportsmint!</span></h2>
                               


                        
                                {/* <h3>T20 World Cup 2024 <br /> Fantasy Universe!</h3> */}

                                {/* <p className="my-3">Join this exciting journey shaping the fantasy world of the 2024 <br /> T20 World Cup.</p> */}

                                {/* <Row>
                                    <Col xs={8} sm={6} md={5} lg={5}>
                                        <Button className="btn_uni w-100">Play now</Button>
                                    </Col>
                                </Row> */}
                               
                                <Button className=" px-5 btn-light"
                                   
                                    style={{
                                        height:'45px',
                                        fontWeight:"bold"
                                    }}
                                ><a onClick={scrollToTarget} className="text-dark">Stake Now</a></Button>
                            </div>
            </section>
        </div>
        </>
    )
}