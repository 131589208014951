import React, { useRef } from 'react';
// import Check from "./Icon/Check";
// import Cardbg from "./Icon/Card_bg";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../style/css/pages/Home/InvestorBenefits.css";
import Check from "./Check";
import Cardbg from "./CardBg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import {
    Web3Modal,
    Web3Button,
    useWeb3Modal,
    Web3ModalProps,
} from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import toast, { Toaster } from "react-hot-toast";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider"
import { purchaseInvestorPlan, findAddressVesting, getPurchaseInvestorPlanDetails } from "../Web3/Connector"
import config from "../../config/config";
import { Spin } from "antd";

export default function InvestorBenefits() {

    const navigate = useNavigate();
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const _useWeb3ModalProvider = Web3ModalProvider();

    const { open } = useWeb3Modal();

    const openConnectiomModel = () => {
        open()
    }

    const [activeCard, setActiveCard] = useState("");
    const [data, setData] = useState();
    const [processStart, setProcessStart] = useState(false)






    const includes = [
        {
            data: "Your staking is secured for 44 months, with tokens allocated based on current market value."
        },
        {
            data: " Tokens remain in the vesting wallet for 24 months before a gradual release."
        },
        {
            data: "Revenue sharing starts after the BETA launch, lasting 44 months."
        },
        {
            data: "For the first 24 months, revenue is shared based on the value of your full staked tokens."
        },
        {
            data: "From the 25th month, revenue sharing decreases progressively each month."
        },
        {
            data: "30% of revenue is distributed equally among the three staking pools ($25,000, $50,000, $100,000)."
        },



    ]


    const [show, setShow] = useState(false);
    const [goldShow, setGoldShow] = useState(false)
    const [platinumShow, setPlatinumShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleGoldShow = () => setGoldShow(true);
    const handlePlatinumShow = () => setPlatinumShow(true);
    const handleGoldClose = () => setGoldShow(false)
    const handlePlatinumClose = () => setPlatinumShow(false)
    const [selectPackage, setSelectPackage] = useState([]);


    const investMethod = async (plan, cardName) => {

        let res = await getPurchaseInvestorPlanDetails(
            address,
            _useWeb3ModalProvider,
            plan,
        );

        if (res.status) {

            setSelectPackage({
                plan,
                cardName,
                allocationMNT: res.allocation,
                MNT: res.mnt,
                USDT: res.usdt
            });
            setShow(true);
        } else {
            toast.error(res.message);
            return;
        }

        return;
    }

    const investNow = async (coin) => {
        // if (!isConnected) {
        //   props.connectWallet(true)
        //   setTimeout(() => {
        //     props.connectWallet(false)
        //   })
        //   return;
        // }    
        if (!coin) {
            toast.error("Please select payment methond");
            return;
        }
        setProcessStart(true)
        setActiveCard(selectPackage.cardName)
        let result = await purchaseInvestorPlan(
            address,
            _useWeb3ModalProvider,
            selectPackage.plan,
            coin
        );
        console.log(result)
        if (result.status) {
            toast.success(result.message);
            setTimeout(() => {
                navigate(`${config.baseUrl}dashboard`);
            }, 2000); // 
        } else {
            toast.error(result.message);
        }
        setActiveCard("")
        setProcessStart(false)


    };


    return (
        <>
            <Toaster />
            <div className="dashboard_home my-5"  >
                <Container>
                    <h2 class="hd" id="targetElement">Staking Plan </h2>
                    <p className="text-center">Select Your Ideal Staking Pool and Leverage Our Strategic Revenue Sharing Model.</p>
                    <Row>
                        <Col lg={6}>
                            <Row className="d-flex flex-column">

                                <Col lg={12} className=" mt-3">
                                    <div className={activeCard ? "box d-block p-4 text-start gold active overflow-hidden card-border" : "box d-block p-4 text-start gold overflow-hidden card-border"} >

                                        <div className="position-relative">
                                            <div className="mb-2">
                                                <h4 className="text-gradient">GOLD</h4>

                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-sm-baseline align-items-center flex-wrap gap-3">
                                                <div className='d-flex flex-column flex-wrap '>
                                                    <h1 className="text-gradient-gold">$25K </h1> <span className='text-gradient-gold' style={{ fontSize: 14 }}>worth MNT tokens</span>
                                                </div>
                                                <Button
                                                    className="px-sm-5 px-3"
                                                    onClick={() => {
                                                        isConnected ? investMethod(25, "GOLD") : openConnectiomModel()
                                                    }}
                                                    disabled={(processStart ? true : false)}
                                                >
                                                    {!processStart ? 'STAKE' : "Processing..."}
                                                </Button>
                                            </div>
                                            {/* <div>
                                        <h5 className="mb-3">Includes:</h5>
                                    </div> */}


                                            {/* <ul className="mb-3 mt-3">
                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Investors allotted MNT tokens equal to investment at current rate.
                                            </span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">5% of tokens released from 25th month, must be claimed.</span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">10% revenue shared with each group from the launch month.
                                            </span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Option to renew investment in 23rd month for continued revenue sharing.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                If renewed, 5% tokens will be released after renewed term.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Revenue share credited monthly in MNT tokens.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Allotted tokens non-transferable.
                                            </span>
                                        </li>
                                    </ul> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} className=" mt-3">
                                    <div className={activeCard ? "box d-block p-4 text-start platinum active overflow-hidden card-border-platinum" : "box d-block p-4 text-start platinum overflow-hidden card-border-platinum"} style={{ backgroundColor: "#131313" }}>

                                        <div className="position-relative">
                                            <div className="mb-2 ">
                                                <h4 className="text-gradient">PLATINUM</h4>

                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-sm-baseline align-items-center flex-wrap gap-3">

                                                <div className='d-flex flex-column flex-wrap '>
                                                    <h1 className="text-gradient-platinum">$50K </h1> <span className='text-gradient-platinum' style={{ fontSize: 14 }}>worth MNT tokens</span>
                                                </div>
                                                <Button
                                                    className="px-sm-5 px-3"
                                                    onClick={() => {
                                                        isConnected ? investMethod(50, "PLATINUM") : openConnectiomModel()
                                                    }}
                                                    disabled={(processStart ? true : false)}
                                                >
                                                    {!processStart ? 'STAKE' : "Processing..."}
                                                </Button>
                                            </div>
                                            {/* <div>
                                        <h5 className="mb-3">Includes:</h5>
                                    </div> */}


                                            {/* <ul className="mb-3 mt-3">
                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Investors allotted MNT tokens equal to investment at current rate.
                                            </span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">5% of tokens released from 25th month, must be claimed.</span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">10% revenue shared with each group from the launch month.
                                            </span>
                                        </li>
                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Option to renew investment in 23rd month for continued revenue sharing.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                If renewed, 5% tokens will be released after renewed term.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Revenue share credited monthly in MNT tokens.
                                            </span>
                                        </li>

                                        <li>
                                            <Check />
                                            <span className="ms-3">
                                                Allotted tokens non-transferable.
                                            </span>
                                        </li>
                                    </ul> */}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} className=" mt-3">
                                    <div className={activeCard ? "box d-block p-4 text-start diamond active overflow-hidden card-border-diamond" : "box d-block p-4 text-start diamond overflow-hidden card-border-diamond"} style={{ backgroundColor: "#131313" }}>

                                        <div className="position-relative">
                                            <div className="mb-2">
                                                <h4 className="text-gradient">DIAMOND</h4>

                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-sm-baseline align-items-center flex-wrap gap-3">
                                                <div className='d-flex flex-column flex-wrap'>
                                                    <h1 className="text-gradient-diamond">$100K </h1> <span className='text-gradient-diamond' style={{ fontSize: 14 }}>worth MNT tokens</span>
                                                </div>

                                                <Button
                                                    className="px-sm-5 px-3"

                                                    onClick={() => {
                                                        isConnected ? investMethod(100, "DIAMOND") : openConnectiomModel()
                                                    }}
                                                >
                                                    {!processStart ? 'STAKE' : "Processing..."}
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} >
                            <div className="fix mt-3 p-0 px-4 pt-2 h-100">
                                <div className="  pt-3" style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.10)" }}>
                                    <h3 className="mb-3 include-h3">Includes:</h3>
                                </div>
                                <div className="mt-3">
                                    {includes.map((item, index) => {
                                        return (
                                            <Row className="gx-5 pb-3" key={index}>
                                                <Col lg={1} md={1} sm={1} xs={2}>
                                                    <img src="image/listicon.svg" />
                                                </Col>
                                                <Col lg={10} md={10} sm={10} xs={10} className="text-start include-list d-flex align-items-center">
                                                    <span className=" ">
                                                        {item.data}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>

                            </div>
                        </Col>
                    </Row>

                </Container>

                <Modal show={show} onHide={handleClose} backdrop="static" className={`${selectPackage?.cardName == 'GOLD' ? 'gold-modal' : selectPackage?.cardName == 'PLATINUM' ? 'platinum-modal' : ''}`}>
                    {/* <Spin spinning={processStart} tip="Loading..." > */}
                    {processStart?<Modal.Header>
                        
                    </Modal.Header>
                    :
                    <Modal.Header closeButton>
                        
                    </Modal.Header>}
                    <Modal.Body >
                        <div >
                            <div className="mb-2">
                                <h2 className={`${selectPackage?.cardName == 'GOLD' ? 'modal-gold-grad' : selectPackage?.cardName == 'PLATINUM' ? 'modal-platinum-grad' : 'modal-text-grad'}`}>{selectPackage?.cardName || 'GOLD'} ${selectPackage?.plan}K</h2>
                            </div>

                            <div>Allocation MNT : <b className="fs-5">{selectPackage?.allocationMNT || 0} MNT</b></div>

                            <hr style={{ color: '#626262' }} />

                            <Row className="mt-4">
                                <Col>
                                    <div className="text-center mb-3">
                                        <small>Payable : {selectPackage?.MNT || 0} MNT</small>
                                    </div>

                                    <button disabled={(processStart ? true : false)} onClick={(e) => investNow('MNT')} className="btn_uni w-100 py-1 d-flex justify-content-center align-items-center">
                                        <img src="image/mrmintcoin.png" width={40} className="img-fluid me-3" />
                                        <div>
                                           { processStart?<div>Loading...</div>:<div>MNT</div>}
                                            {/* <small>( 9211 $ )</small> */}
                                        </div>
                                    </button>

                                </Col>

                                <Col>
                                    <div className="text-center mb-3">
                                        <small>Payable : {selectPackage?.USDT || 0} USDT</small>
                                    </div>

                                    <button disabled={(processStart ? true : false)} onClick={(e) => investNow('USDT')} className="btn_uni w-100 py-1 d-flex justify-content-center align-items-center">
                                        <img src="image/usdt.svg" width={40} className="img-fluid me-3" />
                                        <div>
                                        { processStart?<div>Loading...</div>:<div>USDT</div>}
                                            {/* <small>( 420 $ )</small> */}
                                        </div>
                                    </button>

                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    {/* </Spin> */}
                </Modal>
            </div>
        </>
    )
}