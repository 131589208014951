import React from "react";
import { useNavigate } from "react-router-dom";

import DataTable, { createTheme } from "react-data-table-component";

import DashboardHeader from "./header/Header";
import { useEffect, useState } from "react";
import config from "../../config/config";
import { useAccount } from "wagmi";
import "./css/myslot.css";
import "./css/myinvestment.css";
import "../../assets/style/Faqs.css";
import { IoIosArrowRoundBack } from "react-icons/io";
import Web3ModalProvider from "../ConnectWallet/Web3ModalProvider";
import Cardbg2 from "./Icon/Card_bg2";

import Check from "./Icon/Check";
import Cardbg from "./Icon/Card_bg";
import { purchaseInvestorPlan } from "../Web3/Connector";

import {
  getParams2,
  claimReleasableAmountMNT,
  upgradeVestingDuration,
} from "../Web3/Connector";
import toast, { Toaster } from "react-hot-toast";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Modal,
} from "react-bootstrap";
import SidebarPro from "./sidebar/Sidebar";
import {
  Web3Modal,
  Web3Button,
  useWeb3Modal,
  Web3ModalProps,
} from "@web3modal/wagmi/react";
import { EmptyInvesmentBox } from "./EmptyboxLoader";
import { Loader } from "./Loader";
export default function MyInvestmentTwo(props) {
  const [activeCard, setActiveCard] = useState("");
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  if (!isConnected) {
    navigate(`${config.baseUrl}`);
  }
  const { open } = useWeb3Modal();

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [visibleDiv, setVisibleDiv] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextClick = (nextDiv) => {
    setVisibleDiv(nextDiv);
  };

  const handleBackClick = (previousDiv) => {
    setVisibleDiv(previousDiv);
  };
  const investMethod = async (plan, cardName) => {
    if (!isConnected) {
      open();
      return;
    }
    setActiveCard(cardName);
    let result = await purchaseInvestorPlan(
      address,
      _useWeb3ModalProvider,
      plan
    );
    if (result.status) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    setActiveCard("");
  };
  const [ClubData, setClubData] = useState([]);

  const columns = [
    {
      name: "Claim Amount",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div className="me-2">
            <img src="coin.png" alt="" width={`18px`} />
          </div>
          <div className="d-flex">${row.releasedAmount}MNT</div>
        </div>
      ),
    },
    {
      name: "Time",
      selector: (row) => row.date,
    },
    {
      name: "Status",
      // selector: row => row.status,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <button className="status_btn btn btn-sm">{row.status}</button>
        </div>
      ),
      center: true,
    },

    {
      name: "TNX Hash",
      cell: (row) => (
        <>
          {row.transactionHash.toString().substring(0, 6)}...
          {row.transactionHash
            .toString()
            .substring(
              row.transactionHash.length - 6,
              row.transactionHash.length
            )}
        </>
      ),
    },
  ];

  const tableCustomStyles = {
    headRow: {
      style: {
        color: "rgb(219, 219, 219)",
        background:
          "linear-gradient(90deg, rgba(5, 214, 217, 0.20) 0.3%, rgba(249, 7, 252, 0.20) 99.65%)",
        fontSize: "18px",
      },
    },
    rows: {
      style: {
        color: "rgb(219, 219, 219)",
        backgroundColor: "rgba(23, 25, 28, 1)",
        fontSize: "16px",
      },
    },
    pagination: {
      color: "rgb(219, 219, 219)",
      background: "white",
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#268bd2",
        secondary: "rgba(215, 215, 215, 0.32)",
      },
      background: {
        default: "#000",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "#6b6a6b",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "dark"
  );

  const { collapsed, setCollapsed } = props;

  // match height code start

  const HeightMatch = () => {
    const hMtch = document.getElementsByClassName("h_match_myclub");
    const ary = [];
    for (let i = 0; i < hMtch.length; i++) {
      ary.push(hMtch[i].offsetHeight);
    }
    let max = Math.max(...ary);

    for (let i = 0; i < hMtch.length; i++) {
      hMtch[i].style.height = max + "px";
    }
  };

  useEffect(() => {
    return HeightMatch();
  }, [ClubData.length ? true : false]);

  const _useWeb3ModalProvider = Web3ModalProvider();

  const [params, setParams] = useState([]);

  const [packages, setPackages] = useState([]);
  const [openNote, setOpenNote] = useState(true);
  const [loader, setLoader] = useState(true);
  const [loaderButton, setLoaderButton] = useState(false);


  const [isProviderLoaded, setisProviderLoaded] = useState(false);


  useEffect(() => {
    if (!isProviderLoaded) {
      _useWeb3ModalProvider.then(data => {
        if (data) {
          setisProviderLoaded(true);
          getParamsMethod();
        }
      })
    }
  }, [_useWeb3ModalProvider]);

  const getParamsMethod = async () => {
    if (isConnected) {
      setLoader(true);
      let getParamsResult = await getParams2(address, _useWeb3ModalProvider);
      if (getParamsResult.status) {
        setParams(getParamsResult.data);
        console.log(getParamsResult.data[0].packages);
        setPackages(getParamsResult.data[0].packages);
      }

      setLoader(false);
      setOpenNote(false);
    }
  };

  if (isDisconnected) {
    navigate(`${config.baseUrl}`);
  }
  const columns2 = [
    {
      name: "Amount",

      cell: (row) => (
        <div className="staking-table-button " style={{ width: "150px" }}>
          <p className="mb-0">
            {" "}
            <img
              src="image/mrmintcoin.png"
              width={20}
              height={20}
              style={{ marginRight: 10 }}
              alt=""
            />
            ${row.amount} MNT
          </p>
        </div>
      ),
      width: "25%",
    },
    {
      name: "Time",
      selector: (row) => row.time,
      width: "25%",
    },
    {
      name: "Status",

      cell: (row) => (
        <div
          className="staking-table-button py-2"
          style={{
            width: "150px",
            borderRadius: "5px",
            backgroundColor:
              row.Status === "Completed"
                ? "rgba(115, 197, 143, 0.20)"
                : "rgba(243, 78, 78, 0.20)",
          }}
        >
          <p className="mb-0">{row.Status}</p>
        </div>
      ),
      width: "25%",
    },

    {
      name: "Tx Hash",
      selector: (row) => row.hash,
    },
  ];

  const data2 = [
    {
      id: 1,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 2,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 3,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
    {
      id: 4,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Failed",
    },
    {
      id: 5,
      amount: "500",
      time: "20-01-01 12:32 PM",
      hash: "0xcd3...8cd030",
      Status: "Completed",
    },
  ];
  const customStyles2 = {
    rows: {
      style: {
        minHeight: "62px", // override the row height
        backgroundColor: "transparent",
        color: "white",
        paddingLeft: "10px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "rgba(102, 104, 206, 0.303)",
        borderRadius: "16px 16px 0px 0px",
        color: "white",

        paddingLeft: "10px",
      },
      denseStyle: {
        minHeight: "32px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",

        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
      },
    },
    table: {
      style: {
        color: "white",
        backgroundColor: "transparent",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        backgroundColor: "transparent",
        fontSize: "16px",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        borderBottom: "1px solid #A5A5A523",
      },
    },
    headeRow: {
      style: {
        borderBottom: "1px solid #A5A5A5",
        backgroundColor: "red",
      },
    },
    pagination: {
      style: {
        color: "white",
        fontSize: "13px",
        minHeight: "56px",
        backgroundColor: "transparent",
        borderTopStyle: "solid",
        borderTopWidth: "1px",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "px",
        cursor: "pointer",
        transition: "0.4s",
        color: "white",
        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "white",
          fill: "white",
        },
      },
    },
  };

  return (
    <>
      <div className="dashboard color-container">
        <div className="c1"></div>
        <div className="investor-c2"></div>
        <div className="investor-c3"></div>
        <SidebarPro collapsed={collapsed} setCollapsed={setCollapsed} />
        <div className={collapsed ? "marginL25" : "marginL250"} id="outlet">
          <DashboardHeader
            className={collapsed ? "marginL25" : "marginL250"}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <div className="dashboard_home mt-5 mx-3">
            <section className="mb-5 px-1 px-sm-5 ">
              <Row>
                <div className="newdashboard">
                  <div className="section01">
                    <div>
                      <h2 className=" text-start hd mb-4">Investment</h2>
                      <Row className="gy-4">
                        {loader &&  <Loader count={4} />}
                        {packages.length == 0 && !loader &&
                          <Col lg={12}>
                            <div className={` invest-card px-md-5 px-3 py-4`}>
                              <h4 className="text-center mt-5 mb-5">NO INVESTMENT DATA FOUND</h4>
                            </div>
                          </Col>
                        }
                        {packages.length > 0 && (
                          packages.map((item) => (
                            <Col lg={6}>
                              <div className={`position-relative ${item.package.toLowerCase()} invest-${item.package}-card px-md-5 px-3 py-4`}>
                                <div className="mb-2">
                                  <h4 className="text-gradient">{item.name}</h4>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-sm-baseline align-items-center flex-wrap gap-3">
                                  <h1 className={`text-gradient-${item.package}`}>
                                    ${item.amount}
                                  </h1>
                                  <div>
                                    <Button
                                      className="px-md-5 gold-button"
                                      onClick={() => navigate(`${config.baseUrl}investment-detail/${item.vestingScheduledId}`)}
                                    >
                                      View Details
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </Row>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
