import React from "react";
import { MdArrowOutward } from "react-icons/md";
import { JackInTheBox, Bounce } from 'react-awesome-reveal'
import '../style/css/pages/Home/HowItWorks.css'
import { Col, Row } from "react-bootstrap";

export default function HowItWorks() {
    return (
        <>
            <section className="how_it_works_banner my-5">
                <div className="container">
                    <div className="how_it_works">
                    <h2 class="hd">Why Stake on <span>Sportsmint?</span> </h2>
<p className="text-center mb-4">Capitalize on Innovation and Drive Your Financial Growth.</p>
                       
                        <Row className="gy-4">
                          <Col lg={6} className="d-flex align-items-center justify-content-center">
      <div className="    d-flex align-items-center">

<div className="v_box ">
    <JackInTheBox>
        <div>
            {/* <iframe width="560" height="415" src="https://www.youtube.com/embed/YYgQD2WT3vg?si=uP_sNj6pfRP4b99o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}

            {/* <iframe width="630" height="415" src="https://www.youtube.com/embed/AQYYjKOfAjE?si=a4KNDBVLW8FcKucg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

            <div>
                <video style={{ width: "100%",height:"100%", borderRadius: '25px', border:"1px solid white" }} controls>
                    <source src="image/video.mp4" type="video/mp4" />
                </video>
            </div>

        </div>
        {/* <span className="ply_btn">
<FaPlay />
</span> */}
    </JackInTheBox>
</div>

</div>
                          </Col>
                          <Col lg={6} >
 <div className="  mt-md-0 d-flex align-items-center">
                            <div class="accordion w-100" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button  accord-head" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
       1. Innovative Platform
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong className="accord-data"> SportsMint integrates blockchain technology to ensure transparency, security, and an unparalleled user experience. </strong>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button accord-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        2. Secure Vesting
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <strong className="accord-data"> Your Staking is secured for 44-months lock, ensuring steady growth and commitment.
      </strong>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button accord-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        3. Attractive Revenue Sharing
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <strong className="accord-data"> Enjoy robust revenue sharing that starts strong and continues to provide returns.
      </strong></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button accord-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        4. Secure Staking
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <strong className="accord-data"> SportsMint offers top-tier security measures to protect your stake from fraud and unauthorized access, ensuring peace of mind for all stakeholders.</strong>  </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFive">
      <button class="accordion-button accord-head collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        5. Scalable Platform Growth
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <strong className="accord-data"> SportsMint is designed with scalability in mind, allowing for continuous platform enhancements and feature expansions. </strong> </div>
    </div>
  </div>
</div>
                            </div>
                          </Col>
                        </Row>
                  
                           
                          

                            {/* <div className="col-lg-3 mt-4 mt-md-0 d-flex align-items-center mt-3 mt-lg-0">
                                <div>

                                    <div className="choose_game">
                                        <h3>4. Customize Your Club</h3>
                                        <p>Customize your club by choosing preferred NFTs.</p>
                                    </div>



                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>5. Refer and Earn</h3>
                                        <p>Refer new users and incentivize yourself.</p>

                                    </div>

                                    <hr style={{ background: 'white', height: '2px' }} className="my-3" />

                                    <div className="choose_game mt-3">
                                        <h3>6. Explore The Collections</h3>
                                        <p>Explore your dashboard for every actions</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
               
            </section>
        </>
    )
}