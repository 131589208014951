import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
import { BsTelegram } from "react-icons/bs";
import '../style/css/layout/Footer.css'

export default function Footer() {
    return (
        <>
            <section className="footer_banner py-4">
                <div className="container">
                    <div className="footer py-4">
                        <div className="row">
                            <div className="col-md-8 mb-3 ">
                            
                                <div className="me-3 d-flex flex-column gap-3 col1 align-items-center  align-items-sm-start">
                                <img src="image/product01.svg" style={{width:"200px", height:"100%", objectFit:"contain"}} alt="" />
                                <div className="d-flex flex-row flex-wrap gap-3 align-items-center  align-items-sm-start justify-content-center justify-content-sm-start ">
                                    <a href="https://sportsmint.io/aboutus" target="_blank" rel="noopener noreferrer">About Us</a>
                                    <a href="https://sportsmint.io/faq" target="_blank" rel="noopener noreferrer">FAQ</a>
                                    <a href="https://sportsmint.io/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                    <a href="https://sportsmint.io/cookiepolicy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                                   </div>
                                </div>
                              
                            </div>
                            <div className="col-md-4 ">
                            <div className="icons d-flex align-items-center justify-content-center justify-content-sm-end mb-2">
                            <small className="me-1">Product by : </small>
                            <a href="https://mrmint.io/" target="_blank" rel="noopener noreferrer">
                                        <img src="image/logo/logo.png" width={`100px`} style={{ marginTop: "-8px" }} className="ftr_logo" />
                                    </a>
                            </div>
                                <ul className="icons d-flex justify-content-center justify-content-sm-end">
                                    <li className="facebook_icon">
                                        <a href="https://www.facebook.com/profile.php?id=61557738151728" target="_blank" rel="noopener noreferrer">
                                            <FaFacebook  />
                                        </a>
                                    </li>
                                    <li className="instagram_icon">
                                        <a href="https://www.instagram.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaInstagram  />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://twitter.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaXTwitter />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://www.linkedin.com/company/sports-mint" target="_blank" rel="noopener noreferrer">
                                            <FaLinkedin />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://t.me/sportsmint" target="_blank" rel="noopener noreferrer">
                                            <BsTelegram />
                                        </a>
                                    </li>
                                </ul>

                            
                            </div>
                        </div>

                        

                        {/* <div className="row">
                            <div className="col-md-6">
                                <a href="mailto:info@sportsmint.io">Connect with us on: info@sportsmint.io </a>
                            </div>
                            <div className="col-md-6">
                                <div className="d-block d-md-flex justify-content-end align-items-end h-100">© 2024 Sportsmint. All rights reserved.</div>
                            </div>
                        </div> */}
                    </div>
                    <hr />
                    <div className="footer py-4">
                        <div className="row">
                           
                            <div className="col-md-12 ">
                           
                               

                                <div className="footer_right text-center text-lg-end">© 2024 Sportsmint. All rights reserved.</div>
                            </div>
                        </div>

                        

                        {/* <div className="row">
                            <div className="col-md-6">
                                <a href="mailto:info@sportsmint.io">Connect with us on: info@sportsmint.io </a>
                            </div>
                            <div className="col-md-6">
                                <div className="d-block d-md-flex justify-content-end align-items-end h-100">© 2024 Sportsmint. All rights reserved.</div>
                            </div>
                        </div> */}
                    </div>
                </div>
                
            </section>



            {/* <section className="footer_banner">
                <div className="container">
                    <div className="footer py-4">
                        <div className="row">
                            <div className="col-md-8 d-flex align-items-center justify-content-center justify-content-md-start">
                                <ul className="d-block d-md-flex mb-0">
                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/aboutUs">About Us</Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/contact">Contact </Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/privacyPolicy">Privacy Policy</Link></li>

                                    <li className="text-center mt-3 mt-md-0 me-md-4"><Link to="/cookiePolicy">Cookie Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4 mt-3">
                                <ul className="icons d-flex">
                                    <li className="facebook_icon">
                                        <a href="https://www.facebook.com/profile.php?id=61557738151728" target="_blank" rel="noopener noreferrer">
                                            <FaFacebook />
                                        </a>
                                    </li>
                                    <li className="instagram_icon">
                                        <a href="https://www.instagram.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaInstagram />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://twitter.com/sportsmint_" target="_blank" rel="noopener noreferrer">
                                            <FaXTwitter />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://www.linkedin.com/company/sports-mint" target="_blank" rel="noopener noreferrer">
                                            <FaLinkedin />
                                        </a>
                                    </li>
                                    <li className="twitter_icon">
                                        <a href="https://t.me/sportsmint" target="_blank" rel="noopener noreferrer">
                                            <BsTelegram />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <a href="mailto:info@sportsmint.io">Connect with us on: info@sportsmint.io </a>
                            </div>
                            <div className="col-md-6">
                                <div className="d-block d-md-flex justify-content-end align-items-end h-100">© 2024 Sportsmint. All rights reserved.</div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="partner-logo">
                                    <div className="d-md-flex align-items-center text-white">
                                        <div className="me-3">
                                            <span className="me-1">Product by : </span><a href="https://mrmint.io/" target="_blank">
                                                <img src="image/logo/logo.png" width={`100px`} style={{ marginTop: "-8px" }} className="ftr_logo" />
                                            </a>
                                        </div>
                                        <div>
                                            <span className="me-1">Tech Partner :</span><a href="https://www.espsofttech.com/" target="_blank">
                                                <img src="image/logo/LogoESP.png" width={`90px`} className="ftr_logo" />
                                            </a>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    )
}